import type { ISession, MultiFactorAuthInfo, TranslationKey } from '@nexdynamic/squeegee-common';
import { uuid, wait } from '@nexdynamic/squeegee-common';
import Axios from 'axios';
import { validateSecondFactor } from '../Account/TwoFactorAuth/validateSecondFactor';
import { ApplicationState } from '../ApplicationState';
import { Data } from '../Data/Data';
import { SqueegeeLocalStorage } from '../Data/SqueegeeLocalStorage';
import { LoaderEvent } from '../Events/LoaderEvent';
import { GlobalFlags } from '../GlobalFlags';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { OneSignal } from '../OneSignal';
import { Utilities, deleteCookie, getCookie } from '../Utilities';
import { Api } from './Api';

export class RethinkDbAuthClient {
    public static async clearFreeSession() {
        if (GlobalFlags.isDevServer) {
            Logger.info('Not clearing free session in dev mode');
            return;
        }
        if (/(?:\?|&)ass=true(?:&|$)/.test(document.location?.href || '')) {
            window.history.replaceState({}, '', document.location.href.replace('?ass=true', '').replace('&ass=true', ''));
            Logger.info('Not clearing free session due to ass query param');
            return;
        }

        if (!this.session) {
            Logger.info('Not clearing free session due to no session');
            return;
        }

        if (!this.session.freeDevice) {
            Logger.info('Not clearing free session due to not being a free device');
            return;
        }
        ApplicationState.signOut(false);
    }

    public static async updateDefaultAccount(defaultAccount?: string) {
        await Api.put(Api.apiEndpoint, '/api/authentication/default-account', { defaultAccount });
        if (!RethinkDbAuthClient.session) return;
        RethinkDbAuthClient.session.defaultAccount = defaultAccount;
        await RethinkDbAuthClient.setSession(RethinkDbAuthClient.session);
    }

    public static async setSession(session?: ISession) {
        if (
            session &&
            RethinkDbAuthClient._session &&
            session.key === RethinkDbAuthClient._session.key &&
            session.value === RethinkDbAuthClient._session.value &&
            session.expires === RethinkDbAuthClient._session.expires &&
            session.unverified === RethinkDbAuthClient._session.unverified &&
            session.defaultAccount === RethinkDbAuthClient._session.defaultAccount &&
            session.secondFactorType === RethinkDbAuthClient._session.secondFactorType &&
            session.secondFactorHash === RethinkDbAuthClient._session.secondFactorHash
        )
            return;

        if (session) {
            Logger.info('Updating the session cache due to session being updated');
            await SqueegeeLocalStorage.setItem('squeegee-session', JSON.stringify(session));
            RethinkDbAuthClient._sessionCached = session;
            OneSignal.instance?.setExternalUserId(session.email);
        } else {
            Logger.info('Clearing the session cache due to session being cleared');
            await SqueegeeLocalStorage.removeItem('squeegee-session');
            delete RethinkDbAuthClient._sessionCached;
            OneSignal.instance?.removeExternalUserId();
            Data.deleteCookie('squeegee-session-cookie');
        }
    }

    private static _sessionCached: ISession | undefined;
    private static get _session(): ISession | undefined {
        if (!RethinkDbAuthClient._sessionCached) RethinkDbAuthClient._sessionCached = RethinkDbAuthClient.initSession();

        return RethinkDbAuthClient._sessionCached;
    }

    private static initSession(): ISession | undefined {
        try {
            const sessionAsString = SqueegeeLocalStorage.getItem('squeegee-session');

            let session: ISession | undefined;
            if (sessionAsString) session = JSON.parse(sessionAsString);

            //Check for a session cookie prefer this over local storage
            const cookie = getCookie('squeegee-session-cookie');
            if (cookie) {
                session = JSON.parse(decodeURIComponent(cookie));
                if (session) {
                    SqueegeeLocalStorage.setItem('squeegee-session', JSON.stringify(session));
                    const account = ApplicationState.getAccountOverride();
                    if (account && account.userEmail !== session.email) {
                        ApplicationState.setAccountOverride();
                    }
                }
                deleteCookie('squeegee-session-cookie');
            }

            if (!session || !session.expires || session.expires < new Date().getTime()) {
                SqueegeeLocalStorage.removeItem('squeegee-session');
                return;
            }

            return session;
        } catch (error) {
            Logger.error('Error during parse local storage session.');
        }
    }

    public static async checkQuerySession(url = (document.location && document.location.href) || '') {
        const sessionKeyRegex = /(?:^|&|\?)session-key=(.*?)(?:$|&|#)/.exec(url);
        const sessionKey = (sessionKeyRegex && sessionKeyRegex.length === 2 && sessionKeyRegex[1]) || undefined;

        const sessionValueRegex = /(?:^|&|\?)session-value=(.*?)(?:$|&|#)/.exec(url);
        const sessionValue = (sessionValueRegex && sessionValueRegex.length === 2 && sessionValueRegex[1]) || undefined;

        try {
            if (sessionKey && sessionValue) {
                new LoaderEvent(true);

                const applicationRouteWithLeadingSlash = document.location.pathname;
                const urlSearchParams = new URLSearchParams(document.location.search);
                urlSearchParams.delete('session-key');
                urlSearchParams.delete('session-value');

                if (GlobalFlags.isHttp && window.history) {
                    window.history.replaceState(
                        undefined,
                        document.title,
                        `${applicationRouteWithLeadingSlash}?${urlSearchParams.toString()}`
                    );
                }

                try {
                    await RethinkDbAuthClient.setSession();

                    if (!Api.isConnected) await wait(1000);
                    let session = (
                        await Axios.get(Api.apiEndpoint + '/api/authentication/session', {
                            headers: { Authorization: `Bearer ${sessionKey}:${sessionValue}` },
                        }).catch(() => undefined)
                    )?.data;

                    if (!session) {
                        await wait(1000);
                        session = (
                            await Axios.get(Api.apiEndpoint + '/api/authentication/session', {
                                headers: { Authorization: `Bearer ${sessionKey}:${sessionValue}` },
                            }).catch(() => undefined)
                        )?.data;
                    }

                    if (!session) throw 'Social session could not be loaded.';

                    await RethinkDbAuthClient.setSession(session);

                    const queryStringParams: Record<string, string> = {};
                    if (!applicationRouteWithLeadingSlash) queryStringParams['access'] = uuid();
                    for (const [key, value] of urlSearchParams.entries()) queryStringParams[key] = value;
                    Utilities.goToRootUrl({ queryStringParams, applicationRouteWithLeadingSlash });
                } catch (error) {
                    Logger.error('Error during set the session from the query.', <any>error);
                    new NotifyUserMessage(
                        'Unable to sign you straight in, please reload the app and you will be signed in.' as TranslationKey
                    );
                }
                new LoaderEvent(false);
            }
        } catch (error) {
            Logger.error('Error during checkQuerySession', { sessionKey, error });
        }
    }

    public static get hasLocalSession() {
        return !!this._session;
    }

    public static get session(): ISession | undefined {
        if (
            RethinkDbAuthClient._session &&
            RethinkDbAuthClient._session.expires &&
            RethinkDbAuthClient._session.expires < new Date().getTime()
        ) {
            return void ApplicationState.signOut(false);
        }

        return RethinkDbAuthClient._session;
    }

    public static async register(
        name: string,
        email: string,
        isOptedInToMarketing: boolean,
        password: string,
        language: string,
        referrer: string,
        contactNumber: string
    ): Promise<boolean> {
        try {
            const createdOnDeviceType = GlobalFlags.isAppleMobileApp
                ? 'ios (app)'
                : GlobalFlags.isAndroidMobileDevice && GlobalFlags.isMobileApp
                ? 'android (web app)'
                : GlobalFlags.isAppleMobileDevice
                ? 'ios (web)'
                : GlobalFlags.isAndroidMobileDevice
                ? 'android (web)'
                : 'desktop (web app)';
            const result = await Axios.post(Api.apiEndpoint + '/api/authentication/register', {
                name,
                email,
                isOptedInToMarketing,
                password,
                language,
                referrer,
                contactNumber,
                createdOnDeviceType,
            });
            if (!result || !result.data || !result.data.registered) throw new Error('No response sent by registration service.');
            return true;
        } catch (error) {
            RethinkDbAuthClient.processErrorMessage(error, 'notifications.registration-failed');
            return false;
        }
    }

    public static async signin(
        email: string,
        password: string,
        clearLocalStorage = false
    ): Promise<{ signedIn: boolean; userDoesNotExist?: boolean; error?: string }> {
        return new Promise<{ signedIn: boolean; userDoesNotExist?: boolean; error?: string }>(async (resolve, reject) => {
            try {
                if (clearLocalStorage) await Data.clearLocalStorage();
                const result = await Axios.post<ISession>(Api.apiEndpoint + '/api/authentication/signin', { email, password }).catch(
                    error => {
                        return new Promise<{ data: ISession | undefined }>(async (resolve, reject) => {
                            if (error?.response?.status === 429) {
                                return reject(error);
                            }
                            // Run 2fa if it is requested by the server.
                            if (error?.response?.status === 401 && error?.response?.headers?.['www-authorization'] === '2fa') {
                                const type: MultiFactorAuthInfo['type'] = error.response.headers['www-authorization-type'];
                                if (!type) return reject(error);

                                let session = error?.response?.data as ISession | undefined;
                                if (!session) return reject(error);

                                session = await validateSecondFactor(type, session);

                                if (!session) return reject(`Two factor ${type} authentication failed`);
                                return resolve({ data: session });
                            }
                            return reject(error);
                        });
                    }
                );
                if (!result?.data) return reject('No session sent by signin service');
                await RethinkDbAuthClient.setSession(result.data);
                return resolve({ signedIn: true });
            } catch (error) {
                console.log('auth error', error);
                const userDoesNotExist = error?.response?.data?.[0] === 'Invalid email address';
                if (userDoesNotExist && !ApplicationState.canShowAccountManagement) return resolve({ signedIn: false, userDoesNotExist });

                const message = RethinkDbAuthClient.processErrorMessage(error, 'notifications.signin-failed');
                return resolve({ signedIn: false, error: message });
            }
        });
    }

    public static async signInOAuth(
        provider: string,
        state: { code: string; id_token: string; state: string; client_id: string; mode: 'app' | 'web'; native?: true },
        clearLocalStorage = false
    ): Promise<boolean> {
        return new Promise<boolean>(async resolve => {
            try {
                if (clearLocalStorage) {
                    await Data.clearLocalStorage();
                    await wait(1000);
                }
                const result = await Axios.post<ISession>(Api.apiEndpoint + `/api/authentication/social/${provider}/complete`, state);
                if (!result || !result.data) throw new Error('No session sent by signin service');
                await RethinkDbAuthClient.setSession(result.data);
                Logger.info('OAuth sign in complete.', result.data);
                return resolve(true);
            } catch (error) {
                Logger.error('Failed to sign in with OAuth.', { provider, error });
                const message = `Failed to sign in with ${provider} error: ${error.message}`;
                new NotifyUserMessage(message as TranslationKey);
                return resolve(false);
            }
        });
    }

    private static processErrorMessage(error: any, defaultMessage: TranslationKey) {
        let message = defaultMessage;
        let logError = true;
        if (typeof error === 'string') {
            logError = false;
            message = error as TranslationKey;
        } else if (error && error.response && error.response.status === 429) {
            logError = false;
            message = 'notifications.too-many-signin-attemps';
        } else if (error && error.response === undefined) {
            logError = false;
            message = 'notifications.servers-unavailable';
        } else if (error && error.response && error.response.data && Array.isArray(error.response.data) && error.response.data.length) {
            logError = false;
            message = error.response.data.join(', ');
        }
        if (logError) Logger.error(`Error during processErrorMessage in RethinkDbAuthClient`, error);
        new NotifyUserMessage(message);
        return message;
    }

    public static async validateSession(): Promise<boolean> {
        try {
            const session = RethinkDbAuthClient.session;
            if (!session) return false;

            if (Api.apiEndpoint && Api.isConnected) {
                const client = Axios.create();
                const response = await client.get(Api.apiEndpoint + '/api/authentication/session', {
                    headers: { Authorization: `Bearer ${session.key}:${session.value}` },
                });
                if (response && response.status === 200 && response.data) {
                    await RethinkDbAuthClient.setSession(response.data);
                } else throw { response };
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                Logger.error('A session was used that is invalid.', error);
                await RethinkDbAuthClient.setSession();
                document.location.reload();
            } else if (error && error.response && error.response.status === 403) {
                Logger.error('A session was used that does not have permission.', error);
                await RethinkDbAuthClient.setSession();
                document.location.reload();
            } else if (error && error.response && error.response.status === 400) {
                Logger.info('Failed to get session, bad request.', error);
            } else if (error && (error.code === 'ECONNABORTED' || error.message === 'Network Error')) {
                Logger.info('Failed to get session, network connection was aborted or failed.', error);
            } else {
                Logger.error(
                    'Error in validateSession() on RethinkDbAuthClient, connection failed due to a connection or request error.',
                    error
                );
            }
        }
        return !!RethinkDbAuthClient.session;
    }

    public static async signOut(session?: ISession) {
        if (session) {
            try {
                localStorage.setItem('url-logout-event', 'true');
                await Api.post(Api.apiEndpoint, '/api/authentication/signout', {}, undefined, false, { timeout: 250 });
            } catch (error) {
                Logger.info('Probably already has no session.', error);
            }
        }
        await Data.clearLocalStorage();
    }

    public static async requestPasswordResetEmail(email: string): Promise<
        | {
              success: true;
          }
        | {
              success: false;
              error: TranslationKey;
          }
    > {
        try {
            const response = (await Axios.post(Api.apiEndpoint + '/api/authentication/reset-password', { email })).data;
            if (response && response.sent)
                return {
                    success: true,
                };
        } catch (error) {
            if (error?.response?.status === 429) {
                return { success: false, error: 'notifications.too-many-password-reset-attemps' };
            }

            Logger.error('Error in requestPasswordResetEmail() on RethinkDbAuthClient', { email, error });
        }
        return {
            success: false,
            error: 'notifications.reset-email-send-error',
        };
    }

    public static async resendConfirmationEmail(email: string): Promise<boolean> {
        try {
            const response = (await Axios.post(Api.apiEndpoint + '/api/authentication/resend-confirmation', { email })).data;
            if (response && response.sent) return true;
        } catch (error) {
            if (error?.response?.status === 429) {
                new NotifyUserMessage('notifications.too-many-resend-confirmation-email-attemps');
            }
            Logger.error('Error in resendConfirmationEmail() on RethinkDbAuthClient', { email, error });
        }
        return false;
    }

    public static getSessionKeyAndValue(): string | undefined {
        const session = RethinkDbAuthClient.session;
        if (!session) return undefined;
        return `${session.key}:${session.value}`;
    }
}
