import type {
    AccountUser,
    Customer,
    IJobNotificationModel,
    Job,
    JobGroup,
    Service,
    StoredObject,
    Tag,
    Team,
    Transaction,
    TranslationKey,
} from '@nexdynamic/squeegee-common';
import {
    Alert,
    FrequencyBuilder,
    FrequencyType,
    JobOccurrence,
    JobOccurrenceStatus,
    JobOccurrenceTagInstances,
    JobOrderData,
    TagType,
    distinctArrayByRef,
    replaceMessageTokensWithModelValues,
    searchScheduleItems,
    shouldLetThreadIn,
    sortByCreatedDateAsc,
    sortByCreatedDateDesc,
    sortByDateAsc,
    sortByDateDesc,
    splitName,
    wait,
} from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../ApplicationState';
import { DateFromNowValueConverter } from '../Converters/DateFromNowValueConverter';
import { KEEP_OCCURRENCES_AFTER_X_WEEKS } from '../Customers/ClientArchiveService';
import { Data } from '../Data/Data';
import type { DataChangeNotificationType } from '../Data/DataChangeNotificationType';
import { DialogAnimation } from '../Dialogs/DialogAnimation';
import { Prompt } from '../Dialogs/Prompt';
import { prompt } from '../Dialogs/ReactDialogProvider';
import { ScheduleActionDialog } from '../Dialogs/ScheduleActionDialog';
import { SendMessageToCustomer } from '../Dialogs/SendMessageToCustomer';
import { getSecondsTimestampForSchedule } from '../Dialogs/getDateAndTimeForSchedule';
import { EmailTemplateService } from '../EmailTemplates/EmailTemplateService';
import { LoaderEvent } from '../Events/LoaderEvent';
import type { IFilterItem, IOption } from '../Filters/Filter';
import type { IScheduleFilterItemDictionary, IScheduleSortItemDictionary, IScheduleViewFilterArgs } from '../Filters/Filters';
import { InvoiceService } from '../Invoices/InvoiceService';
import { ConfirmDayCompletedDialog } from '../Jobs/Components/ConfirmDayCompletedDialog';
import { JobOccurrenceTags } from '../Jobs/Components/JobOccurrenceTags';
import { JobPricingDialog } from '../Jobs/Components/JobPricing';
import { DateFilterOption } from '../Jobs/Filters/DateFilterOption'; // TODO: move to schedule
import { JobOccurrenceService } from '../Jobs/JobOccurrenceService';
import { JobService } from '../Jobs/JobService';
import { Logger } from '../Logger';
import type { IMenuBarAction } from '../Menus/IMenuBarAction';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { SendNotificationService } from '../Notifications/SendNotificationService';
import { RoundService } from '../Rounds/RoundService';
import { Api } from '../Server/Api';
import { RethinkDbAuthClient } from '../Server/RethinkDbAuthClient';
import { SqueegeeTools } from '../SqueegeeTools';
import { SelectTagsDialog } from '../Tags/Components/SelectTagsDialog';
import { TimerService } from '../Tracking/TimerService';
import { AssignmentService } from '../Users/Assignees/AssignmentService';
import { SelectUserOrTeamDialog } from '../Users/SelectUserOrTeamDialog';
import { SelectWorkloadDialog } from '../Users/SelectWorkloadDialog';
import { UserService } from '../Users/UserService';
import type { Workload } from '../Users/Workload';
import { Utilities, calculateFormattedTax, getStandardMessageTokensInUse, openSystemBrowser } from '../Utilities';
import { t } from '../t';
import { ScheduleActionEvent } from './Actions/ScheduleActionEvent';
import type { ScheduleByGroup } from './Components/ScheduleByGroup';
import type { ScheduleItemStatus } from './Components/ScheduleItem';
import { ScheduleItem } from './Components/ScheduleItem';
import { ScheduleItemGroup } from './Components/ScheduleItemGroup';
import type { ReminderSendResult } from './ReminderSendResult';
import type { ScheduleItemMessageModel } from './ScheduleItemMessageModel';
import type { ISortOption } from './Views/ISortData';
import { defaultQuickScheduleOptions } from './defaultScheduleDialogOptions';
import { getAdditionalInfoForScheduleItems } from './getAdditionalInfoForScheduleItems';

export class ScheduleService {
    static occurrenceArchiveCutoffDate?: string;

    private static refreshOccurrenceArchiveCutoffDate() {
        if (!ApplicationState.getSetting<boolean>('global.archive-occurrences-before-days', true)) {
            return (ScheduleService.occurrenceArchiveCutoffDate = undefined);
        }

        ScheduleService.occurrenceArchiveCutoffDate = moment().subtract(KEEP_OCCURRENCES_AFTER_X_WEEKS, 'weeks').format('YYYY-MM-DD');
    }

    public static async setDefaultAssignee(settingDefaultUser = false): Promise<boolean> {
        const dialog = new SelectUserOrTeamDialog(
            undefined,
            undefined,
            undefined,
            undefined,
            'assignment.select-default-assignee',
            settingDefaultUser
        );
        const teamOrUser = await dialog.show(DialogAnimation.SLIDE_UP);
        if (!dialog.cancelled) {
            ApplicationState.account.defaultAssignee =
                !teamOrUser || !teamOrUser._id
                    ? <any>null
                    : teamOrUser.resourceType === 'team'
                    ? teamOrUser._id
                    : (teamOrUser as AccountUser).email;
            ApplicationState.save();
            return true;
        }

        if (
            ApplicationState.account.defaultAssignee &&
            !UserService.getUserOrTeam(ApplicationState.account.defaultAssignee) &&
            !UserService.getUser(ApplicationState.account.defaultAssignee)
        ) {
            delete ApplicationState.account.defaultAssignee;
            ApplicationState.save();
        }

        return false;
    }

    public static getScheduleForJobsGrouped(
        jobOrJobs: Array<Job> | Job,
        targetDate: moment.Moment = moment(),
        dateFilterOption?: DateFilterOption,
        statuses: Array<JobOccurrenceStatus> = [],
        groupByField: keyof ScheduleItem = 'date',
        maxFutureOccurrences?: number,
        userAndTeamIds?: Array<string>,
        isUserAndTheirTeams?: boolean
    ): ScheduleByGroup {
        const jobs = Array.isArray(jobOrJobs) ? jobOrJobs : [jobOrJobs];

        const schedule = this.getScheduleForJobs(
            jobs,
            targetDate,
            dateFilterOption,
            statuses,
            maxFutureOccurrences,
            userAndTeamIds,
            isUserAndTheirTeams
        );

        const scheduleByGroup: { [group: string]: { [jobId: string]: ScheduleItem } } = {};

        for (let i = 0; i < schedule.length; i++) {
            const scheduleItem = schedule[i];

            const groupByValue =
                scheduleItem[groupByField] === undefined || typeof scheduleItem[groupByField] === 'object'
                    ? 'Unknown'
                    : <string>scheduleItem[groupByField];
            if (!scheduleByGroup[groupByValue]) scheduleByGroup[groupByValue] = {};
            scheduleByGroup[groupByValue][scheduleItem.occurrence._id] = scheduleItem;
        }

        return scheduleByGroup;
    }

    public static async markAppointmentRemindersSent(scheduleItems: Array<ScheduleItem>) {
        const updates: Array<StoredObject> = [];
        for (let i = 0; i < scheduleItems.length; i++) {
            const scheduleItem = scheduleItems[i];
            if (scheduleItem.job.frequencyType === FrequencyType.NoneRecurring) {
                if (!scheduleItem.job.reminderSent) {
                    const customer = Utilities.copyObject<Customer>(scheduleItem.customer);
                    const job = customer.jobs[scheduleItem.job._id];
                    if (job) {
                        customer.jobs[scheduleItem.job._id].reminderSent = true;
                        updates.push(customer);
                    }
                }
            }

            if (!scheduleItem.occurrence.reminderSent) {
                scheduleItem.occurrence.reminderSent = true;
                updates.push(scheduleItem.occurrence);
            }
        }

        if (updates.length) {
            await Data.put(updates);
        }
    }

    public static async sendAppointmentRemindersBySms(scheduleItems: Array<ScheduleItem>): Promise<ReminderSendResult> {
        const failure = {
            total: scheduleItems.length,
            sent: 0,
            failed: scheduleItems.length,
            missingSmsOrEmail: 0,
            status: 'cancelled',
        } as const;
        if (!ApplicationState.isVerifiedForMessaging) {
            new NotifyUserMessage('notification.account-email-not-verified-message-sending');
            return failure;
        }

        const updates: Array<StoredObject> = [];
        let sendAtSecondsTimestamp: number | undefined;
        let sentOrScheduled = 0;
        let missingSmsOrEmail = 0;
        let templateShown = false;
        for (const scheduleItem of scheduleItems) {
            try {
                const notificationMethod = scheduleItem.customer.defaultNotificationMethod;
                if (
                    notificationMethod !== 'email-and-sms' &&
                    notificationMethod !== 'email-and-sms2' &&
                    notificationMethod !== 'sms' &&
                    notificationMethod !== 'sms2'
                ) {
                    continue;
                }

                const number =
                    notificationMethod === 'sms' || notificationMethod === 'email-and-sms'
                        ? <string>scheduleItem.customer.telephoneNumber
                        : <string>scheduleItem.customer.telephoneNumberOther;

                if (!number) {
                    missingSmsOrEmail++;
                    const name = scheduleItem.customer.name || 'customer';
                    const address =
                        scheduleItem.customer.address && scheduleItem.customer.address.addressDescription
                            ? `(${scheduleItem.customer.address.addressDescription})`
                            : '';
                    const alert = new Alert(
                        `SMS Failed`,
                        t(notificationMethod.includes('sms2') ? 'alerts.customer-has-no-sms2' : 'alerts.customer-has-no-sms1', {
                            name,
                            address,
                        }),
                        'sms',
                        [{ id: scheduleItem.customer._id, name: `Customer`, icon: 'person' }]
                    );
                    Data.put(alert);
                    continue;
                }

                let templateText = ApplicationState.messagingTemplates.smsAppointmentReminder?.trim() || '';

                const {
                    addressDescription,
                    dateFromNow,
                    roundName,
                    assignedTo,
                    duration,
                    formattedPrice,
                    formattedDate,
                    serviceList,
                    serviceListLong,
                    occurrence: { time = 'any time' },
                    date,
                } = scheduleItem;

                let assignedToFirstName = '';
                let assignedToLastName = '';
                if (assignedTo) {
                    const nameParts = splitName(assignedTo);
                    if (nameParts) {
                        assignedToFirstName = nameParts.firstname || '';
                        assignedToLastName = nameParts.lastname || '';
                    }
                }
                const scheduleItemMessageModel: ScheduleItemMessageModel = {
                    addressDescription: addressDescription || '',
                    dateFromNow,
                    roundName,
                    assignedTo: assignedTo === 'Unassigned' ? t('notification.a-member-of-the-team') : assignedTo,
                    assignedToFirstName,
                    assignedToLastName,
                    duration,
                    formattedPrice: formattedPrice || '',
                    formattedDate,
                    serviceList,
                    serviceListLong,
                    time,
                    scheduledDate: date,
                };

                const messageModel = await Utilities.getStandardMessageModel({
                    customer: scheduleItem.customer,
                    isHtml: false,
                    additionalModelProperties: scheduleItemMessageModel,
                    templateText,
                });

                const originalTemplateText = templateText;

                templateText = replaceMessageTokensWithModelValues({
                    model: messageModel,
                    message: templateText,
                    options: { yesLabel: t('general.yes'), noLabel: t('general.no') },
                });

                if (!templateShown) {
                    templateShown = true;
                    new LoaderEvent(false);
                    const confirmPrompt = new Prompt(
                        'prompts.sample-message-sms-title',
                        <TranslationKey>(
                            `${ApplicationState.localise('prompts.sample-message-text')}<br><br>${templateText.replace(/\n/g, '<br>')}`
                        ),
                        {
                            okLabel: 'general.send',
                            altLabel: ApplicationState.features.scheduledMessaging ? 'general.schedule' : undefined,
                            cancelLabel: 'general.cancel',
                            coverViewport: true,
                            cssClass: 'scrollable-cover',
                        }
                    );

                    const result = await confirmPrompt.show();

                    if (confirmPrompt.cancelled) return failure;

                    if (!result) {
                        sendAtSecondsTimestamp = await getSecondsTimestampForSchedule({
                            options: {
                                direction: 'backwards',
                                quickSchedule: defaultQuickScheduleOptions,
                                targetDate: new Date(scheduleItem.date),
                                isEmail: false,
                            },
                            message: originalTemplateText,
                            model: messageModel,
                        });
                        if (!sendAtSecondsTimestamp) return failure;
                        templateText = ScheduleService.scheduledMessageUpdateTokens(
                            originalTemplateText,
                            sendAtSecondsTimestamp,
                            messageModel,
                            scheduleItem.customer._id
                        );
                    }

                    new LoaderEvent(true);
                }

                const description = 'Appointment Reminder for ' + scheduleItem.formattedDate;

                const sender = SendNotificationService.notificationFrom;
                const jobOccurrenceReminderId = scheduleItem.occurrence._id;

                const sent = await SendNotificationService.send(
                    description,
                    number,
                    scheduleItem.customer._id,
                    'SMS',
                    sender,
                    templateText,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    jobOccurrenceReminderId,
                    sendAtSecondsTimestamp
                );

                if (sent) {
                    sentOrScheduled++;
                    if (scheduleItem.job.frequencyType === FrequencyType.NoneRecurring) {
                        if (!scheduleItem.job.reminderSent) {
                            const customer = Utilities.copyObject<Customer>(scheduleItem.customer);
                            const job = customer.jobs[scheduleItem.job._id];
                            if (job) {
                                customer.jobs[scheduleItem.job._id].reminderSent = true;
                                updates.push(customer);
                            }
                        }
                    }
                    if (!scheduleItem.occurrence.reminderSent) {
                        scheduleItem.occurrence.reminderSent = true;
                        updates.push(scheduleItem.occurrence);
                    }
                }
            } catch (error) {
                Logger.error('Error sending SMS reminder', error);
            }
        }

        if (updates.length) await Data.put(updates);

        return {
            sent: sentOrScheduled,
            failed: scheduleItems.length - sentOrScheduled,
            missingSmsOrEmail,
            total: scheduleItems.length,
            status: sendAtSecondsTimestamp ? 'scheduled' : 'sent',
        };
    }

    public static async sendAppointmentRemindersByEmail(scheduleItems: Array<ScheduleItem>): Promise<ReminderSendResult> {
        const failure: ReminderSendResult = {
            total: scheduleItems.length,
            sent: 0,
            failed: scheduleItems.length,
            missingSmsOrEmail: 0,
            status: 'cancelled',
        };

        if (!ApplicationState.isVerifiedForMessaging) {
            new NotifyUserMessage('notification.account-email-not-verified-message-sending');
            return failure;
        }

        const updates: Array<StoredObject> = [];
        let sendAtSecondsTimestamp: number | undefined;

        let showedPreview = false;
        let sentOrScheduled = 0;

        let missingSmsOrEmail = 0;
        for (const scheduleItem of scheduleItems) {
            if (!scheduleItem.customer.email) {
                missingSmsOrEmail++;
                const name = scheduleItem.customer.name || 'customer';
                const address =
                    scheduleItem.customer.address && scheduleItem.customer.address.addressDescription
                        ? `(${scheduleItem.customer.address.addressDescription})`
                        : '';
                const alert = new Alert(`Email Failed`, t('alerts.customer-has-no-email', { name, address }), 'email', [
                    { id: scheduleItem.customer._id, name: `Customer`, icon: 'person' },
                ]);
                Data.put(alert);
                continue;
            }

            const fromName = SendNotificationService.notificationFrom;

            let templateText = ApplicationState.messagingTemplates.emailAppointmentReminder?.trim() || '';

            const isHtml =
                (ApplicationState.messagingTemplates &&
                    ApplicationState.messagingTemplates.smsInvoice &&
                    ApplicationState.messagingTemplates.emailAppointmentReminderIsHtml) ||
                false;
            const {
                addressDescription,
                dateFromNow,
                roundName,
                assignedTo,
                duration,
                formattedPrice,
                formattedDate,
                serviceList,
                serviceListLong,
                occurrence: { time = 'any time' },
                date,
            } = scheduleItem;
            let assignedToFirstName = '';
            let assignedToLastName = '';
            if (assignedTo) {
                const nameParts = splitName(assignedTo);
                if (nameParts) {
                    assignedToFirstName = nameParts.firstname || '';
                    assignedToLastName = nameParts.lastname || '';
                }
            }
            const scheduleItemMessageModel: ScheduleItemMessageModel = {
                addressDescription: addressDescription || '',
                dateFromNow,
                roundName,
                assignedTo: assignedTo === 'Unassigned' ? t('notification.a-member-of-the-team') : assignedTo,
                assignedToFirstName,
                assignedToLastName,
                duration,
                formattedPrice: formattedPrice || '',
                formattedDate,
                serviceList,
                serviceListLong,
                time,
                scheduledDate: date,
            };

            const messageModel = await Utilities.getStandardMessageModel({
                customer: scheduleItem.customer,
                isHtml,
                additionalModelProperties: scheduleItemMessageModel,
                templateText,
            });

            const originalTemplateText = templateText;

            templateText = EmailTemplateService.getSimpleHtml(
                replaceMessageTokensWithModelValues({
                    model: messageModel,
                    message: templateText,
                    options: { yesLabel: t('general.yes'), noLabel: t('general.no') },
                })
            );

            if (!showedPreview) {
                showedPreview = true;
                new LoaderEvent(false);
                const confirmPrompt = new Prompt(
                    'prompts.sample-message-email-title',
                    <TranslationKey>`${ApplicationState.localise('prompts.sample-message-text')}<br><br>${templateText}`,
                    {
                        okLabel: 'general.send',
                        altLabel: ApplicationState.canScheduleEmails ? 'general.schedule' : undefined,
                        cancelLabel: 'general.cancel',
                        coverViewport: true,
                        cssClass: 'scrollable-cover',
                    }
                );
                // so true = send now, false = schedule
                const result = await confirmPrompt.show();
                if (confirmPrompt.cancelled) return failure;

                if (!result) {
                    sendAtSecondsTimestamp = await getSecondsTimestampForSchedule({
                        options: {
                            direction: 'backwards',
                            quickSchedule: defaultQuickScheduleOptions,
                            targetDate: new Date(scheduleItem.date),
                            isEmail: true,
                        },
                    });
                    if (!sendAtSecondsTimestamp) return failure;
                    templateText = EmailTemplateService.getSimpleHtml(
                        ScheduleService.scheduledMessageUpdateTokens(
                            originalTemplateText,
                            sendAtSecondsTimestamp,
                            messageModel,
                            scheduleItem.customer._id
                        )
                    );
                }
                new LoaderEvent(true);
            }

            //if we have a sendAtSecondsTimestamp then we are scheduling the email to be sent so the appointmentFormattedFromDate will need to be from the scheduled date
            const appointmentFormattedFromDate = DateFromNowValueConverter.getFromBaseDate(
                moment(scheduleItem.date),
                sendAtSecondsTimestamp ? moment(sendAtSecondsTimestamp * 1000) : undefined
            );

            if (
                await SendNotificationService.send(
                    `Your ${fromName} Appointment ${appointmentFormattedFromDate} ${scheduleItem.formattedDate}`,
                    scheduleItem.customer.email,
                    scheduleItem.customer._id,
                    'Email',
                    fromName,
                    templateText,
                    ApplicationState.account.bccEmailNotifications,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    scheduleItem.occurrence._id,
                    sendAtSecondsTimestamp
                )
            ) {
                sentOrScheduled++;
                if (scheduleItem.job.frequencyType === FrequencyType.NoneRecurring) {
                    if (!scheduleItem.job.reminderSent) {
                        const customer = Utilities.copyObject<Customer>(scheduleItem.customer);
                        const job = customer.jobs[scheduleItem.job._id];
                        if (job) {
                            customer.jobs[scheduleItem.job._id].reminderSent = true;
                            updates.push(customer);
                        }
                    }
                }
                if (!scheduleItem.occurrence.reminderSent) {
                    scheduleItem.occurrence.reminderSent = true;
                    updates.push(scheduleItem.occurrence);
                }
            } else
                new NotifyUserMessage(
                    <TranslationKey>(
                        (ApplicationState.localise('general.failed') +
                            ': ' +
                            scheduleItem.customerName +
                            ' - ' +
                            (scheduleItem.job.location?.addressDescription || ''))
                    )
                );
        }

        if (updates.length) await Data.put(updates);

        return {
            total: scheduleItems.length,
            sent: sentOrScheduled,
            failed: scheduleItems.length - sentOrScheduled,
            missingSmsOrEmail,
            status: sendAtSecondsTimestamp ? 'scheduled' : 'sent',
        };
    }

    public static async viewJobNotification(type: 'receipt' | 'complete', scheduleItem: ScheduleItem) {
        const formattedTax = calculateFormattedTax(scheduleItem.occurrence.price, scheduleItem.customer._id, ApplicationState.account);
        if (!formattedTax) return false;

        const { subtotal, tax, taxConfig, total } = formattedTax;
        const model: IJobNotificationModel = {
            ownerEmail: ApplicationState.dataEmail,
            occurrenceId: scheduleItem.occurrence._id,
            customerName: scheduleItem.customerName,
            workerName: scheduleItem.assignedTo,
            address: scheduleItem.addressDescription || '',
            completedDate: moment(scheduleItem.date).format('ll'),
            services: scheduleItem.serviceList,
            signatureId: (scheduleItem.signature && scheduleItem.signature._id) || '',
            fullname: (scheduleItem.signature && scheduleItem.signature.fullname) || '',
            signedDate: (scheduleItem.signature && moment(scheduleItem.signature.isoSignedDate).format('lll')) || '',
            status: type === 'complete' ? 'Work completed.' : 'Payment received with thanks.',
            type,
            subtotal,
            tax,
            total,
            priceIncludesTax: taxConfig.priceIncludesTax,
            taxEnabled: taxConfig.taxEnabled,
            hideTax: taxConfig.hideTax,
        };

        openSystemBrowser(Api.currentHostAndScheme + '/go/j?model=' + encodeURIComponent(JSON.stringify(model)));
    }

    public static async sendJobNotification(type: 'receipt' | 'complete', scheduleItem: ScheduleItem, email?: string) {
        if (!ApplicationState.isVerifiedForMessaging) {
            new NotifyUserMessage('account.email-not-verified-enable-to-send-messages');
            return;
        }

        email = email || scheduleItem.customer.email;

        if (!email) return false;

        const formattedTax = calculateFormattedTax(scheduleItem.occurrence.price, scheduleItem.customer._id, ApplicationState.account);
        if (!formattedTax) return false;

        const { subtotal, tax, taxConfig, total } = formattedTax;

        const model: IJobNotificationModel = {
            ownerEmail: ApplicationState.dataEmail,
            occurrenceId: scheduleItem.occurrence._id,
            customerName: scheduleItem.customerName,
            workerName: scheduleItem.assignedTo,
            address: scheduleItem.addressDescription || '',
            completedDate: moment(scheduleItem.date).format('ll'),
            services: scheduleItem.serviceList,
            signatureId: (scheduleItem.signature && scheduleItem.signature._id) || '',
            fullname: (scheduleItem.signature && scheduleItem.signature.fullname) || '',
            signedDate: (scheduleItem.signature && moment(scheduleItem.signature.isoSignedDate).format('lll')) || '',
            status: type === 'complete' ? 'Work completed.' : 'Payment received with thanks.',
            type,
            subtotal,
            tax,
            total,
            priceIncludesTax: taxConfig.priceIncludesTax,
            taxEnabled: taxConfig.taxEnabled,
            hideTax: taxConfig.hideTax,
        };

        SendNotificationService.send(
            `${scheduleItem.addressDescription} ${type === 'complete' ? 'completed' : 'paid'}`,
            email,
            scheduleItem.customer._id,
            'Email',
            ApplicationState.account.businessName || ApplicationState.account.name,
            model,
            undefined,
            undefined,
            undefined,
            undefined,
            type
        );

        return true;
    }

    public static getNextScheduleItemForCustomer(customerId: string, startingFrom?: string) {
        try {
            const customer = Data.get<Customer>(customerId);
            const nextScheduleItems: Array<ScheduleItem> = [];

            if (customer && customer.jobs) {
                const tomorrow = moment(startingFrom).add(1, 'day').format('YYYY-MM-DD');
                for (const jobId in customer.jobs) {
                    const job = customer.jobs[jobId];
                    if (job) {
                        const nextScheduleItem = ScheduleService.getNextDueScheduleItemForJob(job, tomorrow);
                        if (nextScheduleItem) {
                            nextScheduleItems.push(nextScheduleItem);
                        }
                    }
                }
            }

            if (nextScheduleItems.length) return nextScheduleItems.sort(sortByDateAsc)[0];
        } catch (error) {
            Logger.error('Error getting next schedule item for customer', { customerId, error });
        }
    }

    public static getPreviousScheduleItemForJob(job: Job, fromDate: string) {
        const scheduleItemsArray = ScheduleService.getScheduleForJobs(job, moment(fromDate).subtract(1, 'year'), undefined);

        return scheduleItemsArray.filter(x => x.date < fromDate).sort(sortByDateDesc)[0];
    }

    public static getLastScheduleItemForJob(job: Job, fromDate: string) {
        const scheduleItemsArray = ScheduleService.getScheduleForJobs(job, moment(fromDate).subtract(1, 'year'), undefined);

        return scheduleItemsArray.filter(x => x.date < fromDate).sort(sortByDateDesc)[0];
    }

    public static getNextScheduleItemForJob(job: Job, fromDate: string) {
        const scheduleItemsArray = ScheduleService.getScheduleForJobs(job, moment(fromDate));

        return scheduleItemsArray.filter(x => x.date > fromDate).sort(sortByDateAsc)[0];
    }

    public static getNextDueScheduleItemForJob(job: Job, fromDate: string) {
        const scheduleItemsArray = ScheduleService.getScheduleForJobs(job, moment(fromDate), undefined, [JobOccurrenceStatus.NotDone]);

        return scheduleItemsArray.filter(x => x.date >= fromDate).sort(sortByDateAsc)[0];
    }

    public static getJobScheduleByCustomerIdAndJobId(
        customerId: string,
        jobId: string,
        targetDate: moment.Moment,
        dateFilterOption?: DateFilterOption,
        status?: Array<JobOccurrenceStatus>
    ): ScheduleItem[] {
        const customer = Data.get<Customer>(customerId);
        const job = customer && customer.jobs[jobId];
        return (job && ScheduleService.getScheduleForJobs(job, targetDate, dateFilterOption, status)) || [];
    }

    public static getJobSchedule(
        job: Job,
        targetDate: moment.Moment = moment(),
        dateFilterOption?: DateFilterOption,
        status: Array<JobOccurrenceStatus> = [JobOccurrenceStatus.NotDone],
        groupBy: keyof ScheduleItem = 'date',
        maxFutureOccurrences?: number
    ): ScheduleByGroup {
        return ScheduleService.getScheduleForJobsGrouped(job, targetDate, dateFilterOption, status, groupBy, maxFutureOccurrences);
    }

    public static getCustomerJobSchedule(
        customer: Customer,
        targetDate: moment.Moment = moment(),
        dateFilterOption?: DateFilterOption,
        status: Array<JobOccurrenceStatus> = [JobOccurrenceStatus.NotDone],
        groupBy: keyof ScheduleItem = 'date'
    ): ScheduleByGroup {
        const jobs = Object.keys((customer && customer.jobs) || {}).map(x => customer.jobs[x]);
        return ScheduleService.getScheduleForJobsGrouped(jobs, targetDate, dateFilterOption, status, groupBy);
    }

    public static getUserAndTeamIds(email?: string) {
        if (!email) {
            if (!RethinkDbAuthClient.session || !RethinkDbAuthClient.session.email) return [];
            email = RethinkDbAuthClient.session.email;
        }

        const user = UserService.getUser(email);

        if (!user) throw 'No user record found for current user. Are you synced with server?';

        const teamIds = UserService.getTeams()
            .filter(x => x.members?.includes(user._id))
            .map(x => x._id);

        return [user._id, ...teamIds];
    }

    public static getScheduledJobsForCurrentUserByDay(
        targetDate: moment.Moment = moment(),
        dateFilterOption?: DateFilterOption,
        status: Array<JobOccurrenceStatus> = [JobOccurrenceStatus.NotDone],
        groupBy: keyof ScheduleItem = 'date'
    ) {
        if (!ApplicationState.multiUserEnabled) return ScheduleService.getScheduledJobsByDay(targetDate, dateFilterOption, status, groupBy);

        const userAndTeamIds = ScheduleService.getUserAndTeamIds();
        return ScheduleService.getScheduledJobsForUsersAndTeamsByDay(
            userAndTeamIds,
            targetDate,
            dateFilterOption,
            status,
            groupBy,
            undefined,
            true
        );
    }

    public static getScheduledJobsForUsersAndTeamsByDay(
        userAndTeamIds: Array<string>,
        targetDate: moment.Moment = moment(),
        dateFilterOption?: DateFilterOption,
        status: Array<JobOccurrenceStatus> = [JobOccurrenceStatus.NotDone],
        groupBy: keyof ScheduleItem = 'date',
        jobIds?: Array<string>,
        isUserAndTheirTeams?: boolean
    ) {
        console.log('getScheduledJobsForUsersAndTeamsByDay called');
        const jobs = Data.all<Customer>('customers').reduce((all: Array<Job>, next) => {
            for (const jobId in next.jobs || {}) {
                if (!jobIds || jobIds.indexOf(jobId) > -1) all.push(next.jobs[jobId]);
            }
            return all;
        }, []);

        const scheduleByDay = ScheduleService.getScheduleForJobsGrouped(
            jobs,
            targetDate,
            dateFilterOption,
            status,
            groupBy,
            undefined,
            userAndTeamIds,
            isUserAndTheirTeams
        );
        if (!dateFilterOption || !dateFilterOption.start || !dateFilterOption.end || dateFilterOption.start === dateFilterOption.end)
            return scheduleByDay;
        const scheduleByDayWithBlanks: ScheduleByGroup = {};
        const start = moment(dateFilterOption.start);
        const end = moment(dateFilterOption.end);
        while (start.isSameOrBefore(end, 'day')) {
            const dayKey = start.format('YYYY-MM-DD');
            scheduleByDayWithBlanks[dayKey] = scheduleByDay[dayKey] || {};
            start.add(1, 'day');
        }
        return scheduleByDayWithBlanks;
    }

    //WTF? REPORTS DONT DELETE
    public static getSchedule(start: string, end: string) {
        const jobs = JobService.getJobs();

        const schedule = ScheduleService.getScheduleForJobs(jobs, moment(start), new DateFilterOption('', start, end), [
            JobOccurrenceStatus.Done,
            JobOccurrenceStatus.NotDone,
            JobOccurrenceStatus.Skipped,
        ]);

        return schedule;
    }

    public static getScheduledJobsByDay(
        targetDate: moment.Moment = moment(),
        dateFilterOption?: DateFilterOption,
        status: Array<JobOccurrenceStatus> = [JobOccurrenceStatus.NotDone],
        groupBy: keyof ScheduleItem = 'date',
        roundId?: string,
        jobIds?: Array<string>
    ) {
        const jobs = Data.all<Customer>('customers').reduce((all: Array<Job>, next) => {
            for (const jobId in next.jobs || {}) {
                if (
                    (!jobIds || jobIds.indexOf(jobId) > -1) &&
                    (!roundId || (next.jobs[jobId].rounds && next.jobs[jobId].rounds.some(r => r._id === roundId)))
                )
                    all.push(next.jobs[jobId]);
            }
            return all;
        }, []);
        const scheduleByDay = ScheduleService.getScheduleForJobsGrouped(jobs, targetDate, dateFilterOption, status, groupBy);
        if (!dateFilterOption || !dateFilterOption.start || !dateFilterOption.end || dateFilterOption.start === dateFilterOption.end)
            return scheduleByDay;
        const scheduleByDayWithBlanks: ScheduleByGroup = {};
        const start = moment(dateFilterOption.start);
        const end = moment(dateFilterOption.end);
        while (start.isSameOrBefore(end, 'day')) {
            const dayKey = start.format('YYYY-MM-DD');
            scheduleByDayWithBlanks[dayKey] = scheduleByDay[dayKey] || {};
            start.add(1, 'day');
        }
        return scheduleByDayWithBlanks;
    }

    public static getScheduleForJobs(
        jobOrJobs: Array<Job> | Job,
        targetDate: moment.Moment = moment(),
        dateFilterOption?: DateFilterOption,
        statuses: Array<JobOccurrenceStatus> = [],
        maxFutureOccurrences = 52,
        userAndTeamIds?: Array<string>,
        isUserAndTheirTeams?: boolean
    ): Array<ScheduleItem> {
        const jobs = !Array.isArray(jobOrJobs) ? [jobOrJobs] : jobOrJobs;

        const schedule: Array<ScheduleItem> = [];
        const searchDate = targetDate.clone().startOf('day');

        const start = dateFilterOption && dateFilterOption.start ? moment(dateFilterOption.start) : undefined;

        const end = dateFilterOption && dateFilterOption.end ? moment(dateFilterOption.end) : undefined;
        const statusQueryDictionary = JobOccurrenceService.getStatusDictionary(statuses);

        const allExistingOccurrences = JobOccurrenceService.getAllExistingOccurrences();
        // const hasDoneOccurrences = Object.entries(allExistingOccurrences).reduce((all, [key, value]) => {
        //     all[key] = value.some(o => o.status === JobOccurrenceStatus.Done);
        //     return all;
        // }, {} as Record<string, boolean>);

        const clearOverduesIfDone = ApplicationState.getSetting('global.jobs.reset-occurrences-on-done', false);

        ScheduleService.refreshOccurrenceArchiveCutoffDate();

        for (const job of jobs) {
            const customer = Data.get<Customer>(job.customerId);
            if (!customer) continue;

            const occurrences: { [groupBy: string]: JobOccurrence } = {};

            ScheduleService.addProjectedAndExistingOccurrencesToSchedule(
                customer,
                schedule,
                job,
                allExistingOccurrences,
                maxFutureOccurrences,
                searchDate,
                occurrences,
                statuses,
                statusQueryDictionary,
                dateFilterOption,
                start,
                end,
                clearOverduesIfDone,
                userAndTeamIds,
                isUserAndTheirTeams
            );
        }

        return schedule;
    }

    private static addProjectedAndExistingOccurrencesToSchedule(
        customer: Customer,
        schedule: Array<ScheduleItem>,
        job: Job,
        allExistingOccurrences: { [jobId: string]: JobOccurrence[] },
        maxFutureOccurrences: number,
        searchDate: moment.Moment,
        occurrences: { [groupBy: string]: JobOccurrence },
        statuses: Array<JobOccurrenceStatus>,
        statusQueryDictionary: { [key: number]: true },
        dateFilterOption: DateFilterOption | undefined,
        start: moment.Moment | undefined,
        end: moment.Moment | undefined,
        clearOverduesIfDone: boolean,
        userAndTeamIds: Array<string> | undefined,
        isUserAndTheirTeams?: boolean
    ) {
        //WTF some legacy props on a job are required for projection code for example firstDate,   priceFirstAppointmentSeparately
        const frequency = job.date
            ? FrequencyBuilder.getFrequency(
                  job.frequencyInterval,
                  job.frequencyType,
                  job.date,
                  job.frequencyDayOfMonth,
                  job.frequencyWeekOfMonth,
                  Array.isArray(job.frequencyDayOfWeek) ? job.frequencyDayOfWeek[0] : job.frequencyDayOfWeek
              )
            : undefined;

        const inactive = !customer || customer.state === 'inactive' || job.state === 'inactive';

        const existingOccurrences = inactive
            ? (allExistingOccurrences[job._id] || []).filter(o => o.status !== JobOccurrenceStatus.NotDone)
            : allExistingOccurrences[job._id] || [];

        const newestDoneOccurrence =
            existingOccurrences.filter(x => x.isoCompletedDate && x.status === JobOccurrenceStatus.Done).sort(sortByCreatedDateDesc)[0] ||
            null;

        if (!inactive && frequency) {
            let units = 1;
            switch (frequency.type) {
                case FrequencyType.Days:
                    if (start && end) units = end.diff(start, 'days') + 1;
                    else units = 356;
                    break;
                case FrequencyType.NoneRecurring:
                    units = 1;
                    break;
                case FrequencyType.Weeks:
                    if (start && end) units = end.diff(start, 'weeks') + 1;
                    else units = 52;
                    break;
                case FrequencyType.DayOfMonth:
                case FrequencyType.MonthlyDayOfWeek:
                    if (start && end) units = end.diff(start, 'months') + 1;
                    else units = 12;
                    break;
            }
            let occurrencesToGet = Math.ceil(units / (frequency.interval || 52));
            if (occurrencesToGet > maxFutureOccurrences) occurrencesToGet = maxFutureOccurrences;
            const dates = frequency.getOccurrenceIsoDates(
                searchDate.format().substring(0, 10),
                start && start.format().substring(0, 10),
                end && end.format().substring(0, 10),
                occurrencesToGet
            );

            for (const date of dates) {
                if (clearOverduesIfDone) {
                    if (newestDoneOccurrence?.isoCompletedDate && date < newestDoneOccurrence.isoCompletedDate) continue;
                }

                if (ScheduleService.occurrenceArchiveCutoffDate) {
                    if (date < ScheduleService.occurrenceArchiveCutoffDate) continue;
                }

                const occurrence = new JobOccurrence(job, date);

                occurrences[date] = occurrence;
            }

            let count = existingOccurrences.length;
            while (count--) {
                const occurrence = existingOccurrences[count];
                const date = (occurrence.isoCompletedDate || occurrence.isoPlannedDate || occurrence.isoDueDate).substring(0, 10);
                if (occurrences[date]) delete occurrences[date];

                if (occurrence.isoPlannedDate && occurrences[occurrence.isoPlannedDate.substring(0, 10)])
                    delete occurrences[occurrence.isoPlannedDate.substring(0, 10)];

                if (occurrences[occurrence.isoDueDate.substring(0, 10)]) delete occurrences[occurrence.isoDueDate.substring(0, 10)];

                if (statuses.length && !statusQueryDictionary[occurrence.status]) existingOccurrences.splice(count, 1);
            }
        }

        const finalOccurrences =
            clearOverduesIfDone && newestDoneOccurrence
                ? Object.values(occurrences).concat(
                      existingOccurrences.filter(
                          x =>
                              x.status !== JobOccurrenceStatus.NotDone ||
                              (newestDoneOccurrence.isoCompletedDate &&
                                  (x.isoPlannedDate || x.isoDueDate) >= newestDoneOccurrence.isoCompletedDate)
                      )
                  )
                : Object.values(occurrences).concat(existingOccurrences);

        for (const occurrence of finalOccurrences) {
            let plannedDate: string;
            if (occurrence.status === JobOccurrenceStatus.NotDone) {
                plannedDate = occurrence.isoPlannedDate || occurrence.isoDueDate;
            } else {
                plannedDate = occurrence.isoCompletedDate || occurrence.isoPlannedDate || occurrence.isoDueDate;
            }

            if (
                (!statuses.length || statusQueryDictionary[occurrence.status]) &&
                (!dateFilterOption || !dateFilterOption.start || dateFilterOption.start <= plannedDate) &&
                (!dateFilterOption || !dateFilterOption.end || dateFilterOption.end >= plannedDate)
            ) {
                if (userAndTeamIds && userAndTeamIds.length) {
                    if (!AssignmentService.isAssigned(occurrence, userAndTeamIds, !!isUserAndTheirTeams)) continue;
                }
                const scheduleItem = new ScheduleItem(job, customer as Customer, occurrence);
                schedule.push(scheduleItem);
                // if (map) map[plannedDate.slice(0, 10)].push(scheduleItem);
            }
        }
    }

    public static async updateScheduleByGroupArray(
        scheduleByGroup: ScheduleByGroup,
        groupLabelFormatter: { (text: string): string } = (text: string) => {
            return text;
        },
        sortGroupSortDirection: 1 | -1 = 1,
        sortGroupItems: ISortOption<keyof ScheduleItem> = {
            text: 'sorting.by-distance',
            value: [{ sortPropertyName: 'distance', sortDirection: 1 }],
        },
        textFilter?: string,
        roundId?: string
    ) {
        const scheduleItemsAndGroupsArray: Array<ScheduleItem | ScheduleItemGroup> = [];
        const scheduleItemGroups: Array<ScheduleItemGroup> = [];
        const groupKeys = Object.keys(scheduleByGroup);

        const round = roundId ? Data.get<Tag>(roundId) : undefined;
        const roundName = round?.description;
        const searchText = `${textFilter || ''}`.trim();
        for (const groupName of groupKeys) {
            const group = scheduleByGroup[groupName];
            const scheduleItemGroup = new ScheduleItemGroup(groupName, groupLabelFormatter(groupName), []);
            scheduleItemGroups.push(scheduleItemGroup);

            const scheduleItems = Object.keys(group).map(x => group[x]);

            const additional = getAdditionalInfoForScheduleItems(scheduleItems);

            scheduleItemGroup.items = searchScheduleItems<ScheduleItem>(scheduleItems, searchText, roundName, undefined, additional);
            scheduleItemGroup.items.sort((a: ScheduleItem, b: ScheduleItem) => {
                return ScheduleService.sortOnProperty(a, b, 0, sortGroupItems);
            });
        }

        if (sortGroupSortDirection !== -1 && sortGroupSortDirection !== 1) sortGroupSortDirection = 1;

        scheduleItemGroups.sort((a, b) => {
            const occurrenceA = a.group || 0;
            const occurrenceB = b.group || 0;

            return occurrenceA < occurrenceB ? -sortGroupSortDirection : occurrenceA > occurrenceB ? sortGroupSortDirection : 0;
        });

        await new Promise<void>(resolve => {
            setTimeout(() => {
                try {
                    for (let i = 0; i < scheduleItemGroups.length; i++) {
                        const scheduleItemGroup = scheduleItemGroups[i];
                        if (scheduleItemGroup.items.length) {
                            scheduleItemsAndGroupsArray.push(scheduleItemGroup);
                            for (let i = 0; i < scheduleItemGroup.items.length; i++) {
                                const scheduleItem = scheduleItemGroup.items[i];
                                scheduleItemsAndGroupsArray.push(scheduleItem);
                            }
                        }
                    }
                } catch (error) {
                    Logger.error(`Error during update of the schedule items array in updateScheduleByGroupArray()`, error);
                }
                return resolve();
            }, 5);
        });

        return scheduleItemsAndGroupsArray;
    }

    private static sortOnProperty(
        a: ScheduleItem,
        b: ScheduleItem,
        propertyIndex: number,
        sortGroupItems: ISortOption<keyof ScheduleItem>
    ): number {
        if (propertyIndex === sortGroupItems.value.length) return 0;
        const propertyName = <keyof ScheduleItem>sortGroupItems.value[propertyIndex].sortPropertyName;
        const sortDirectionValue = sortGroupItems.value[propertyIndex].sortDirection;
        const sortDirection = sortDirectionValue === 1 || sortDirectionValue === -1 ? sortDirectionValue : 1;
        const propertyA = a[propertyName];
        const occurrenceA = propertyA ? (typeof propertyA === 'string' ? propertyA.toLowerCase() : (propertyA || '').toString()) : '0';

        const propertyB = b[propertyName];
        const occurrenceB = propertyB ? (typeof propertyB === 'string' ? propertyB.toLowerCase() : (propertyB || '').toString()) : '0';

        if (occurrenceA !== occurrenceB) {
            return occurrenceA < occurrenceB ? -sortDirection : occurrenceA > occurrenceB ? sortDirection : 0;
        } else {
            propertyIndex++;
            return this.sortOnProperty(a, b, propertyIndex, sortGroupItems);
        }
    }

    public static filter(
        dayArray: Array<ScheduleItem>,
        includeTeamMembers: boolean,
        filterOptions?: IScheduleFilterItemDictionary,
        searchText?: string,
        userAndTeamIds?: Array<string>
    ) {
        searchText = searchText && searchText.toLowerCase();

        // Filter for selected user

        let filteredDayArray =
            userAndTeamIds && userAndTeamIds.length
                ? dayArray.filter(scheduleItem => {
                      if (userAndTeamIds.includes('UNASSIGNED')) {
                          const isUnassigned = AssignmentService.isUnassigned(scheduleItem.occurrence);
                          if (!isUnassigned) return false;
                      } else if (!AssignmentService.isAssigned(scheduleItem.occurrence, userAndTeamIds, includeTeamMembers)) {
                          return false;
                      }

                      if (filterOptions && !ScheduleService.checkScheduleItemForFilters(scheduleItem, filterOptions)) return false;
                      return true;
                  })
                : dayArray.filter(scheduleItem => {
                      if (filterOptions && !ScheduleService.checkScheduleItemForFilters(scheduleItem, filterOptions)) return false;
                      return true;
                  });

        const mobilePrefixes = ApplicationState.account.smsMobilePhonePrefixes?.split(',').map(x => x.trim());

        const additional = getAdditionalInfoForScheduleItems(filteredDayArray);

        if (searchText)
            filteredDayArray = searchScheduleItems<ScheduleItem>(filteredDayArray, searchText, undefined, mobilePrefixes, additional);

        return filteredDayArray;
    }

    public static sort(scheduleItems: Array<ScheduleItem>, filterOptions?: IScheduleSortItemDictionary): Array<ScheduleItem> {
        // force undefined values to top of list
        let propertyValue: (scheduleItem: ScheduleItem) => number | string = (scheduleItem: ScheduleItem) => scheduleItem.plannedOrder;

        const undefinedDefault = typeof propertyValue === 'string' ? 'aaa' : -999;
        const selectedSortOptionValue =
            filterOptions && filterOptions.sortBy.selectedOption ? filterOptions.sortBy.selectedOption.value : 1;

        let ascending =
            filterOptions && filterOptions.direction.selectedOption && filterOptions.direction.selectedOption.value === 'ascending';

        if (selectedSortOptionValue && selectedSortOptionValue === 1) ascending = true;

        if (selectedSortOptionValue) {
            switch (selectedSortOptionValue) {
                case 1:
                    propertyValue = (scheduleItem: ScheduleItem) => scheduleItem.plannedOrder;
                    break;
                case 2:
                    propertyValue = (scheduleItem: ScheduleItem) => scheduleItem.distanceFromMe;
                    break;
                case 3:
                    propertyValue = (scheduleItem: ScheduleItem) =>
                        moment(scheduleItem.occurrence.time || '11:59pm', 'HH:mm A').format('HH:mm');
                    break;
                case 4:
                    propertyValue = (scheduleItem: ScheduleItem) => scheduleItem.occurrence.price || 0;
                    break;
                case 5:
                    propertyValue = (scheduleItem: ScheduleItem) => scheduleItem.date || '2000-01-01';
            }
        }

        return scheduleItems.sort((a, b) => {
            const aValue = propertyValue(a) || undefinedDefault;
            const bValue = propertyValue(b) || undefinedDefault;
            let result = aValue === bValue ? 0 : aValue < bValue ? -1 : 1;
            if (!ascending) result = result * -1;
            return result;
        });
    }
    public static getFilters(scheduleItems: Array<ScheduleItem>): IScheduleViewFilterArgs {
        const servicesD: {
            [id: string]: IOption<string>;
        } = {};

        const roundsD: {
            [id: string]: IOption<string>;
        } = {};

        const statusD: {
            [id: string]: IOption<ScheduleItemStatus | ''>;
        } = {};

        const assigneeD: {
            [id: string]: IOption<string>;
        } = {};

        const existingServices = Data.all<Service>('tags', { type: TagType.SERVICE });
        const existingRounds = Data.all<JobGroup>('tags', { type: TagType.ROUND });

        for (const si of scheduleItems) {
            si.occurrence.services.forEach(x => {
                // exclude deleted/renamed services.
                const description = x.description?.toLowerCase();
                if (!existingServices.some(s => description === s.description?.toLowerCase())) return;

                if (!servicesD[x._id]) servicesD[x._id] = { name: x.description as TranslationKey, value: x._id, count: 0 };
                servicesD[x._id].count++;
                servicesD[x._id].name = (x.description + ' ' + servicesD[x._id].count) as TranslationKey;
            });

            si.occurrence.rounds.forEach(x => {
                // exclude deleted/renamed rounds.
                const description = x.description?.toLowerCase();
                if (!existingRounds.some(r => description === r.description?.toLowerCase())) return;

                if (!roundsD[x._id]) roundsD[x._id] = { name: x.description as TranslationKey, value: x._id, count: 0 };

                roundsD[x._id].count++;
                roundsD[x._id].name = (x.description + ' ' + roundsD[x._id].count) as TranslationKey;
            });

            const status = si.scheduleStatus === undefined ? 'undefined' : si.scheduleStatus;

            if (!statusD[status])
                statusD[status] = { name: ApplicationState.localise(si.statusLabel), value: si.scheduleStatus || '', count: 0 };

            statusD[status].count++;
            statusD[status].name = (ApplicationState.localise(si.statusLabel) + ' ' + statusD[status].count) as TranslationKey;

            const assignees = AssignmentService.getAssignees(si.occurrence);
            if (assignees.length) {
                assignees.forEach(x => {
                    if (!assigneeD[x._id]) assigneeD[x._id] = { name: x.name as TranslationKey, value: x._id, count: 0 };
                    assigneeD[x._id].count++;
                    assigneeD[x._id].name = (x.name + ' ' + assigneeD[x._id].count) as TranslationKey;
                });
            } else {
                if (!assigneeD['UNASSIGNED']) assigneeD['UNASSIGNED'] = { name: 'general.unassigned', value: 'UNASSIGNED', count: 0 };
                assigneeD['UNASSIGNED'].count++;
                assigneeD['UNASSIGNED'].name = (ApplicationState.localise('general.unassigned') +
                    ' ' +
                    assigneeD['UNASSIGNED'].count) as TranslationKey;
            }
        }

        return {
            rounds: Object.keys(roundsD)
                .map(k => roundsD[k])
                .sort((a, b) => a.name.localeCompare(b.name)),
            services: Object.keys(servicesD)
                .map(k => servicesD[k])
                .sort((a, b) => a.name.localeCompare(b.name)),
            status: Object.keys(statusD)
                .map(k => statusD[k])
                .sort((a, b) => a.name.localeCompare(b.name)),
            assignee: Object.keys(assigneeD).map(k => assigneeD[k]),
        };
    }

    protected static checkScheduleItemForFilters(scheduleItem: ScheduleItem, filterOptions: IScheduleFilterItemDictionary) {
        const startDate: string | undefined =
            filterOptions.dateRange &&
            filterOptions.dateRange.selectedOption &&
            filterOptions.dateRange.selectedOption[filterOptions.dateRange.optionValueKey].start;
        const endDate: string | undefined =
            filterOptions.dateRange &&
            filterOptions.dateRange.selectedOption &&
            filterOptions.dateRange.selectedOption[filterOptions.dateRange.optionValueKey].end;

        const assigneeFilters = filterOptions.assignee?.selectedOptions?.map(x => x.value);

        const includeUnassigned = filterOptions.assignee?.selectedOptions?.some(x => x.value === 'UNASSIGNED');

        return (
            (!startDate || scheduleItem.date >= startDate) &&
            (!endDate || scheduleItem.date <= endDate) &&
            this.valuePropertyMatchesFilter(scheduleItem.roundId, filterOptions.rounds) &&
            this.hasServices(scheduleItem.occurrence.services, filterOptions.services) &&
            this.valuePropertyMatchesFilter(scheduleItem.scheduleStatus, filterOptions.status) &&
            (!assigneeFilters?.length ||
                (includeUnassigned && AssignmentService.isUnassigned(scheduleItem.occurrence)) ||
                AssignmentService.isAssigned(
                    scheduleItem.occurrence,
                    assigneeFilters.filter(x => x !== 'UNASSIGNED'),
                    true
                ))
        );
    }

    private static hasServices(scheduleItemServices: Array<Service>, filterServices: IFilterItem<IOption<string, any, any>>) {
        if (!filterServices || !filterServices.selectedOptions || !filterServices.selectedOptions.length) return true;
        const selectedServiceIds = filterServices.selectedOptions;
        return scheduleItemServices.some(service =>
            selectedServiceIds.some(selectedServiceOption => service._id === selectedServiceOption.value)
        );
    }

    private static valuePropertyMatchesFilter(value: any, filter: IFilterItem) {
        if (!filter || !filter.selectedOptions || !filter.selectedOptions.length) return true;

        const options = filter.selectedOptions;

        return options.some(option => option[filter.optionValueKey] === value);
    }

    public static async bulkInvoiceScheduleItems(
        scheduleItems: Array<ScheduleItem>,
        unselectMethod: () => void,
        allowTriggerPayments = true,
        backdateInvoice = false
    ) {
        try {
            const sure = await ScheduleService.areYouSure('update.bulk-invoice-schedule-items-are-you-sure');

            if (!sure) {
                unselectMethod();
                return false;
            }

            new LoaderEvent(true);

            for (const scheduleItem of scheduleItems)
                await InvoiceService.autoCreateInvoiceForOccurrence(
                    scheduleItem.customer,
                    scheduleItem.occurrence,
                    true,
                    undefined,
                    allowTriggerPayments,
                    backdateInvoice
                );

            for (const scheduleItem of scheduleItems) ScheduleItem.refresh(scheduleItem);

            unselectMethod();
            new LoaderEvent(false);
            return true;
        } catch (ex) {
            new LoaderEvent(false);
            Logger.error('Error bulk invoicing schedule items', ex);
            new NotifyUserMessage('fail.invoicing-schedule-items');
            return false;
        }
    }

    public static async bulkCompleteScheduleItems(scheduleItems: Array<ScheduleItem>, unselectMethod: () => void) {
        try {
            const prompt: TranslationKey =
                scheduleItems.length > 1
                    ? 'prompts.confirm-are-you-sure-you-want-to-mark-selected-as-done'
                    : 'prompts.confirm-are-you-sure-you-want-to-do-this-appointment';
            const sure = await ScheduleService.areYouSure(prompt);

            if (!sure) {
                unselectMethod();
                return false;
            }

            const count = scheduleItems.length;
            const completed = await ScheduleService.completeScheduleItems(scheduleItems);
            unselectMethod();

            new LoaderEvent(false);
            if (completed) new NotifyUserMessage('actions.done', { count: count.toString() });
            return completed;
        } catch (ex) {
            new LoaderEvent(false);
            Logger.error('Error bulk completing schedule items', ex);
            new NotifyUserMessage('problem.updating-schedule-items');
            return false;
        }
    }

    public static async bulkRevertScheduleItems(scheduleItems: Array<ScheduleItem>, unselectMethod: () => void) {
        try {
            const prompt: TranslationKey =
                scheduleItems.length > 1
                    ? 'prompts.confirm-are-you-sure-you-want-to-mark-selected-as-not-done'
                    : 'prompts.confirm-are-you-sure-you-want-to-undo-this-appointment';
            const sure = await ScheduleService.areYouSure(prompt);

            if (!sure) {
                unselectMethod();
                return false;
            }

            new LoaderEvent(true, true);
            let fails = 0;
            let successes = 0;
            const count = scheduleItems.length;
            for (const scheduleItem of scheduleItems) {
                if (shouldLetThreadIn(scheduleItem, scheduleItems, 20)) {
                    new LoaderEvent(true, true, 'loader.schedule-items-reverted', undefined, {
                        index: (scheduleItems.indexOf(scheduleItem) + 1).toString(),
                        length: scheduleItems.length.toString(),
                    });
                    await wait(1);
                }
                const response = await JobOccurrenceService.revertJobOccurrence(scheduleItem.occurrence, true);
                if (!response === true) {
                    fails++;
                } else {
                    successes++;
                    scheduleItem.selected = false;
                    // scheduleItem.refresh();
                }
            }
            for (const scheduleItem of scheduleItems) ScheduleItem.refresh(scheduleItem);
            if (fails) {
                new NotifyUserMessage(
                    <TranslationKey>(
                        `Warning failed to revert ${fails} of ${count} jobs. This could be because they have automated payments that have been processed.`
                    )
                );
            } else {
                unselectMethod();
            }
            if (successes) {
                new NotifyUserMessage(<TranslationKey>`Successfully reverted ${successes} of ${count} jobs.'}`);
            }
            new LoaderEvent(false);
            return !!successes;
        } catch (ex) {
            new LoaderEvent(false);
            Logger.error('Error bulk reverting schedule items', ex);
            new NotifyUserMessage('problem.reverting-schedule-items');
            return false;
        }
    }

    public static async bulkAssignScheduleItems(
        scheduleItems: Array<ScheduleItem>,
        unselectMethod: () => void,
        allScheduleItems?: Array<ScheduleItem>
    ) {
        try {
            const dialog = allScheduleItems ? new SelectWorkloadDialog(allScheduleItems, true) : new SelectUserOrTeamDialog();
            const result = await dialog.show(DialogAnimation.SLIDE_UP);
            const userOrTeam = allScheduleItems ? result && (result as Workload).userOrTeam : (result as AccountUser | Team);

            if (!dialog.cancelled) {
                new LoaderEvent(true);
                if (userOrTeam?._id === 'UNASSIGNED') {
                    await ScheduleService.bulkUnassign(scheduleItems);
                } else {
                    await ScheduleService.bulkAssign(scheduleItems, userOrTeam);
                }
                unselectMethod();
                return true;
            }

            return false;
        } catch (ex) {
            Logger.error('Error bulk assigning schedule items', ex);
            new NotifyUserMessage('problem.assigning-schedule-items');
            return false;
        } finally {
            new LoaderEvent(false);
        }
    }

    public static async bulkResetSchedules(scheduleItems: Array<ScheduleItem>, unselectMethod: () => void) {
        try {
            new LoaderEvent(true);
            for (const si of scheduleItems) {
                await JobService.hardResetSchedule(si.job, moment().format('YYYY-MM-DD'), false);
            }
            unselectMethod();
            return true;
        } catch (ex) {
            Logger.error('Error bulk assigning schedule items', ex);
            new NotifyUserMessage('problem.assigning-schedule-items');
            return false;
        } finally {
            new LoaderEvent(false);
        }
    }

    public static async bulkAssign(scheduleItems: Array<ScheduleItem>, useOrTeam: AccountUser | Team | undefined) {
        const assignedTo = !useOrTeam ? undefined : useOrTeam.resourceType === 'team' ? useOrTeam._id : (<AccountUser>useOrTeam)._id;
        if (!assignedTo) throw 'No user or team selected';
        const isTemporaryTeam = useOrTeam?.resourceType === 'team' && ((useOrTeam as Team).activeFrom || (useOrTeam as Team).activeTo);
        let updateJustThisOne = true;
        if (!isTemporaryTeam) {
            new LoaderEvent(false);
            const prompt = new Prompt('global.repeat-job', 'update.assignee-all-appointments-or-just-next-appointment', {
                okLabel: 'general.ok-label-just-this',
                cancelLabel: 'general.cancel',
                altLabel: 'general.all-title',
            });

            updateJustThisOne = await prompt.show();
            if (prompt.cancelled) return;
        }
        new LoaderEvent(true);

        for (const s of scheduleItems) {
            await AssignmentService.assign(s.occurrence, assignedTo, !updateJustThisOne, true, true);
        }

        new LoaderEvent(false);
        for (const s of scheduleItems) ScheduleItem.refresh(s);
    }
    public static async bulkUnassign(scheduleItems: Array<ScheduleItem>) {
        let updateJustThisOne = true;

        new LoaderEvent(false);
        const prompt = new Prompt('global.repeat-job', 'update.assignee-all-appointments-or-just-next-appointment', {
            okLabel: 'general.ok-label-just-this',
            cancelLabel: 'general.cancel',
            altLabel: 'general.all-title',
        });

        updateJustThisOne = await prompt.show();
        if (prompt.cancelled) return;

        new LoaderEvent(true);

        for (const s of scheduleItems) {
            await AssignmentService.unassign(s.occurrence, undefined, !updateJustThisOne, true);
        }

        new LoaderEvent(false);
        for (const s of scheduleItems) ScheduleItem.refresh(s);
    }

    public static async bulkSkipScheduleItems(scheduleItems: Array<ScheduleItem>, unselectMethod: () => void) {
        try {
            const prompt: TranslationKey =
                scheduleItems.length > 1
                    ? 'prompts.confirm-are-you-sure-you-want-to-mark-selected-as-skipped'
                    : 'prompts.confirm-are-you-sure-you-want-to-skip-this-appointment';
            const sure = await ScheduleService.areYouSure(prompt);

            if (!sure) {
                unselectMethod();
                return false;
            }

            const skipReasonResult = await JobOccurrenceService.getSkipReasonIfRequired();
            if (skipReasonResult.cancelled) {
                new NotifyUserMessage('notification.skip-reason-required');
                return;
            }

            new LoaderEvent(true);
            for (let i = 0; i < scheduleItems.length; i++) {
                const scheduleItem = scheduleItems[i];
                await JobOccurrenceService.skipJobOccurrence(scheduleItem.occurrence, true, skipReasonResult);
            }
            unselectMethod();
            new LoaderEvent(false);
            return true;
        } catch (ex) {
            new LoaderEvent(false);
            Logger.error('Error bulk skipping schedule items', ex);
            new NotifyUserMessage('problem.skipping-schedule-items');
            return false;
        }
    }

    public static async bulkReplanScheduleItems(scheduleItems: Array<ScheduleItem>, unselectMethod: () => void) {
        try {
            const scheduleActionDialog = new ScheduleActionDialog(scheduleItems);
            await scheduleActionDialog.show();
            if (!scheduleActionDialog.cancelled) {
                unselectMethod();
                return true;
            }
        } catch (ex) {
            new LoaderEvent(false);
            Logger.error('Error bulk replan schedule items', ex);
            new NotifyUserMessage('problem.skipping-schedule-items');
            return false;
        }
    }

    public static async bulkMessageCustomers(scheduleItems: Array<ScheduleItem>, unselectMethod: () => void) {
        try {
            const customers = scheduleItems.map(s => s.customer).filter(distinctArrayByRef);
            unselectMethod();
            if (!ApplicationState.hasAdvancedOrAbove) return new NotifyUserMessage('problem.messaging-customers-advanced-only');

            const dialog = new SendMessageToCustomer(customers, {
                email: ApplicationState.messagingTemplates.emailMessage,
                sms: ApplicationState.messagingTemplates.smsMessage,
                emailIsHtml: ApplicationState.messagingTemplates.emailMessageIsHtml,
            });
            await dialog.show();
            if (dialog.cancelled) return;
        } catch (error) {
            Logger.error('Error bulk moving round schedule items', error);
            new NotifyUserMessage('problem.messaging-customers');
        } finally {
            new LoaderEvent(false);
        }
    }

    public static async bulkMoveRoundScheduleItems(scheduleItems: Array<ScheduleItem>, unselectMethod?: () => void, toRounds?: JobGroup[]) {
        try {
            const dialog = new SelectTagsDialog(
                scheduleItems.length === 1 ? (scheduleItems[0].job.rounds && scheduleItems[0].job.rounds.slice()) || [] : [],
                TagType.ROUND,
                0,
                1
            );
            let rounds = toRounds;
            if (!rounds) rounds = await dialog.show(DialogAnimation.SLIDE_UP);

            if (dialog.cancelled) return;

            const jobGroup = rounds[0] as JobGroup | undefined;

            if (jobGroup && jobGroup.frequencyData) {
                const confirmPrompt = new Prompt(
                    'prompts.confirm-title',
                    <TranslationKey>(
                        `This is a scheduled round, update ${scheduleItems.length > 1 ? 'these jobs schedules' : 'this job schedule'} to
                    <strong>${RoundService.getRoundFrequencyText(jobGroup)}</strong>?`
                    ),
                    { okLabel: 'general.yes', cancelLabel: 'general.cancel' }
                );

                if (!(await confirmPrompt.show())) {
                    return;
                }
            }
            new LoaderEvent(true);
            for (let i = 0; i < scheduleItems.length; i++) {
                const scheduleItem = scheduleItems[i];
                const job = Utilities.copyObject<Job>(scheduleItem.job);
                scheduleItem.occurrence.rounds = rounds;
                job.rounds = rounds;
                if (jobGroup && jobGroup.frequencyData) {
                    const frequency = FrequencyBuilder.getFrequency(
                        jobGroup.frequencyData.interval,
                        jobGroup.frequencyData.type,
                        jobGroup.frequencyData.firstScheduledDate,
                        jobGroup.frequencyData.dayOfMonth,
                        jobGroup.frequencyData.weekOfMonth,
                        jobGroup.frequencyData.dayOfWeek
                    );
                    const date = frequency.getNextDate();
                    if (date) {
                        job.date = date;
                        job.frequencyType = jobGroup.frequencyData.type;
                        job.frequencyInterval = jobGroup.frequencyData.interval;
                        job.frequencyDayOfMonth = jobGroup.frequencyData.dayOfMonth;
                        job.frequencyDayOfWeek = jobGroup.frequencyData.dayOfWeek;
                        job.frequencyWeekOfMonth = jobGroup.frequencyData.weekOfMonth;
                    }
                }

                await JobService.addOrUpdateJob(
                    job.customerId,
                    job,
                    jobGroup && jobGroup.frequencyData && scheduleItem.occurrence.isoPlannedDate,
                    !jobGroup || !jobGroup.frequencyData,
                    jobGroup && jobGroup.frequencyData && true,
                    true,
                    ['rounds']
                );
            }

            unselectMethod && unselectMethod();
            new LoaderEvent(false);
            return true;
        } catch (ex) {
            new LoaderEvent(false);
            Logger.error('Error bulk moving rounds schedule items', ex);
            new NotifyUserMessage('problem.changing-round-job-group');
            return false;
        }
    }

    public static async replan(
        scheduleItems: Array<ScheduleItem>,
        isoDate: string | undefined,
        adjustSchedule = false,
        existingScheduleItemsOnDay?: Array<ScheduleItem>
    ) {
        const alreadyExists = new Array<ScheduleItem>();
        let unskip = false;
        const areSkippedItems = scheduleItems.some(scheduleItem => scheduleItem.occurrence.status === JobOccurrenceStatus.Skipped);
        if (areSkippedItems) {
            const dialog = prompt('prompts.handle-skipped-replan', 'prompts.handle-skipped-replan-text', {
                altLabel: 'prompts.handle-skipped-replan-alt',
                okLabel: 'prompts.handle-skipped-replan-ok',
                cancelLabel: 'general.cancel',
            });

            const result = await dialog.show();

            if (dialog.cancelled) {
                new LoaderEvent(false);
                return;
            }

            if (result) {
                unskip = false;
            } else if (!result) {
                unskip = true;
            }
        }
        new LoaderEvent(true);
        if (!isoDate) return Logger.error('No date iso passed to replanJobOccurrence');
        for (const scheduleItem of scheduleItems) {
            if (
                (!existingScheduleItemsOnDay &&
                    (await JobOccurrenceService.checkForDuplicateOccurrences(scheduleItem.occurrence, isoDate))) ||
                (existingScheduleItemsOnDay && existingScheduleItemsOnDay.some(x => x.job._id === scheduleItem.job._id))
            ) {
                if (!ApplicationState.getSetting('global.ignore-existing-jobs', false)) {
                    new LoaderEvent(false);
                    await new Prompt('prompts.job-already-scheduled-title', 'prompts.job-already-scheduled-text', {
                        cancelLabel: '',
                        localisationParams: { address: scheduleItem.addressDescription || '' },
                    }).show();
                    return;
                } else alreadyExists.push(scheduleItem);
            }
            if (shouldLetThreadIn(scheduleItem, scheduleItems, 100))
                await new Promise<void>(resolve => requestAnimationFrame(() => resolve()));
        }

        new LoaderEvent(true);
        let fails = 0;
        let done = 0;
        try {
            const multiple = scheduleItems.length > 1;
            for (const scheduleItem of scheduleItems) {
                if (alreadyExists.some(x => x.job._id === scheduleItem.job._id)) {
                    fails++;
                    continue;
                }
                const replannedOccurrence = await JobOccurrenceService.replanOccurrence(
                    scheduleItem.occurrence,
                    isoDate,
                    multiple,
                    adjustSchedule,
                    true,
                    unskip
                );
                if (!replannedOccurrence) fails++;
                else {
                    done++;
                    scheduleItem.updateOccurrence(replannedOccurrence);
                }
                if (shouldLetThreadIn(scheduleItem, scheduleItems, 100))
                    await new Promise<void>(resolve => requestAnimationFrame(() => resolve()));
            }

            requestAnimationFrame(() => {
                new ScheduleActionEvent('action-replan');
                new NotifyUserMessage(
                    <TranslationKey>`${done} appointments replanned successfully ${fails ? ', ' + fails + ' failed to replan.' : ''}`
                );
            });
        } catch (error) {
            new NotifyUserMessage('notifications.oh-no');
            Logger.error(`Error during bulk action replan in schedule service`, { error });
        } finally {
            new LoaderEvent(false);
        }
    }

    //Yeah I know this is really ugly ='( #JacksBadFunction
    public static getBulkActionsForScheduleItem(
        scheduleItems: Array<ScheduleItem>,
        allScheduleItems: Array<ScheduleItem> | undefined,
        bulkActionWrapper: (
            action: (scheduleItems: Array<ScheduleItem>, unselectMethod: () => void) => Promise<any>,
            notifyMessage: string,
            allScheduleItems?: Array<ScheduleItem>
        ) => void,
        simple = false
    ): Array<IMenuBarAction> | undefined {
        const actions: Array<IMenuBarAction> = [];

        if (ApplicationState.multiUserEnabled) {
            actions.push({
                actionType: 'action-assign',
                handler: async () => {
                    bulkActionWrapper(ScheduleService.bulkAssignScheduleItems, 'Job appointments reassigned', allScheduleItems);
                },
                tooltip: 'menubar.re-assign-all',
                roles: ['Owner', 'Admin', 'Planner'],
            });
        }
        actions.push({
            actionType: 'action-move-round',
            handler: async () => {
                bulkActionWrapper(ScheduleService.bulkMoveRoundScheduleItems, 'Jobs moved to a new round');
            },
            tooltip: 'menubar.move-round',
            roles: ['Owner', 'Admin', 'Planner'],
        });

        if (ApplicationState.hasAdvancedOrAbove) {
            actions.push({
                actionType: 'action-message',
                handler: async () => {
                    bulkActionWrapper(ScheduleService.bulkMessageCustomers, 'Job appointments reassigned');
                },
                tooltip: 'menubar.send-message',
                roles: ['Owner', 'Admin', 'Creator'],
            });
        }

        if (simple) return actions;

        if (scheduleItems.every(x => x.status === JobOccurrenceStatus.NotDone)) {
            actions.push(
                {
                    actionType: 'action-skip',
                    handler: async () => {
                        bulkActionWrapper(ScheduleService.bulkSkipScheduleItems, 'Jobs marked as Skipped');
                    },
                    tooltip: 'menubar.mark-all-skipped',
                    roles: ['Owner', 'Admin', 'Planner', 'Creator', 'JobEditor', 'Worker'],
                },
                {
                    actionType: 'action-replan',
                    handler: async () => {
                        bulkActionWrapper(ScheduleService.bulkReplanScheduleItems, 'Jobs Replanned');
                    },
                    tooltip: 'menubar.replan-all',
                    roles: ['Owner', 'Admin', 'Planner'],
                },
                {
                    actionType: 'action-done',
                    handler: async () => {
                        bulkActionWrapper(ScheduleService.bulkCompleteScheduleItems, 'Jobs marked as Done');
                    },
                    tooltip: 'menubar.mark-all-done',
                    roles: ['Owner', 'Admin', 'Worker'],
                }
            );
        } else if (scheduleItems.every(x => x.status === JobOccurrenceStatus.Skipped || x.status === JobOccurrenceStatus.NotDone)) {
            actions.push(
                {
                    actionType: 'action-done',
                    handler: async () => {
                        bulkActionWrapper(ScheduleService.bulkCompleteScheduleItems, 'Jobs marked as Done');
                    },
                    tooltip: 'menubar.mark-all-done',
                    roles: ['Owner', 'Admin', 'Worker'],
                },
                {
                    actionType: 'action-replan',
                    handler: async () => {
                        await bulkActionWrapper(ScheduleService.bulkReplanScheduleItems, 'Jobs Replanned');
                    },
                    tooltip: 'menubar.replan-all',
                    roles: ['Owner', 'Admin', 'Planner'],
                }
            );
        }

        if (scheduleItems.every(x => x.status === JobOccurrenceStatus.Skipped || x.status === JobOccurrenceStatus.Done)) {
            actions.push({
                actionType: 'action-not-done',
                handler: async () => {
                    bulkActionWrapper(ScheduleService.bulkRevertScheduleItems, 'Jobs / Invoices Cancelled');
                },
                tooltip: 'menubar.mark-all-not-done',
                roles: ['Owner', 'Admin', 'Worker'],
            });
        }

        if (
            scheduleItems.every(
                x =>
                    x.status === JobOccurrenceStatus.Done &&
                    x.occurrence.paymentStatus === undefined &&
                    x.occurrence.invoiceTransactionId === undefined
            )
        ) {
            actions.push({
                actionType: 'action-invoice',
                handler: async () => {
                    bulkActionWrapper(ScheduleService.bulkInvoiceScheduleItems, 'Jobs Invoiced');
                },
                tooltip: 'action-invoice',
                roles: ['Owner', 'Admin'],
            });
        }

        // If any of the schedule items are not scheduled, then we can only do a replan
        if (scheduleItems.find(x => x.status === JobOccurrenceStatus.NotScheduled)) {
            actions.push({
                actionType: 'action-replan',
                handler: async () => {
                    bulkActionWrapper(ScheduleService.bulkReplanScheduleItems, 'Jobs Replanned');
                },
                tooltip: 'menubar.replan-all',
                roles: ['Owner', 'Admin', 'Planner'],
            });
        }

        return <IMenuBarAction[]>actions;
    }

    public static async handleDragComplete(
        toIndex: number,
        items: Array<ScheduleItem>,
        itemsDragged: Array<ScheduleItem>,
        ignoreReplanned: boolean
    ) {
        if (!ScheduleService.updateListPlannedOrder(toIndex, items, itemsDragged, ignoreReplanned)) return;

        for (const item of items) {
            item.refreshPlannedOrder();
        }
    }

    public static async initialisePlannedOrder(useRounds?: boolean) {
        let jobOrderData = ScheduleService.getJobOrderData();

        if (jobOrderData) {
            return;
        }

        jobOrderData = new JobOrderData('global-day');

        jobOrderData.data = [];

        const jobs = await JobService.getJobs();
        if (jobs.length === 0) return;

        jobs.sort((a, b) => {
            if (!useRounds) {
                const aNextDate = JobService.getJobNextDueDate(a) || 0;
                const bNextDate = JobService.getJobNextDueDate(b) || 0;
                if (aNextDate > bNextDate) {
                    return 1;
                } else if (aNextDate < bNextDate) {
                    return -1;
                }
            } else {
                const aRound = a.rounds.length ? a.rounds[0]._id : 0;
                const bRound = a.rounds.length ? a.rounds[0]._id : 0;
                if (aRound > bRound) {
                    return 1;
                } else if (aRound < bRound) {
                    return -1;
                }
            }

            let aJobOrder = jobOrderData.data.indexOf(a._id);
            let bJobOrder = jobOrderData.data.indexOf(b._id);
            aJobOrder = aJobOrder > -1 ? aJobOrder : a.plannedOrder || 0;
            bJobOrder = bJobOrder > -1 ? bJobOrder : b.plannedOrder || 0;
            // Else go to the 2nd item
            if (aJobOrder < bJobOrder) {
                return -1;
            } else if (aJobOrder > bJobOrder) {
                return 1;
            } else {
                // nothing to split them
                return 0;
            }
        });

        for (const job of jobs) {
            if (shouldLetThreadIn(job, jobs, 20)) await new Promise<void>(resolve => requestAnimationFrame(() => resolve()));
            if (jobOrderData.data.indexOf(job._id) === -1) {
                jobOrderData.data.push(job._id);
            }
        }

        await Data.put(jobOrderData);
    }

    public static updateListPlannedOrder(
        insertAtIndex: number,
        itemsList: Array<ScheduleItem>,
        itemsInDrag: ScheduleItem[],
        ignoreReplanned: boolean
    ): boolean {
        const jobOrderData = ScheduleService.getJobOrderData();
        if (!jobOrderData) return false;

        const original = JSON.stringify(jobOrderData.data);

        ScheduleService.updateListPlannedOrderInternal(insertAtIndex, itemsList, itemsInDrag, jobOrderData.data, ignoreReplanned);

        const updated = JSON.stringify(jobOrderData.data);
        if (original === updated) return false;

        Data.put(jobOrderData);
        return true;
    }
    private static updateListPlannedOrderInternal(
        insertAtIndex: number,
        itemsList: Array<ScheduleItem>,
        itemsInDrag: ScheduleItem[],
        globalJobOrderArray: Array<string>,
        ignoreReplanned: boolean
    ) {
        try {
            if (!itemsList.length) return false;
            const toItemsList = itemsList.filter(item => itemsInDrag.indexOf(item) === -1);
            if (!toItemsList.length) toItemsList.push(itemsList[0]);

            let addAfterItem = false;
            if (insertAtIndex === toItemsList.length) {
                addAfterItem = true;
                insertAtIndex--;
            }

            const insertAtItem = toItemsList[insertAtIndex];

            let insertAtId: string =
                (!ignoreReplanned &&
                    insertAtItem.occurrence.isoPlannedDate &&
                    insertAtItem.occurrence.isoPlannedDate !== insertAtItem.occurrence.isoDueDate &&
                    insertAtItem.occurrence._id) ||
                insertAtItem.job._id;

            const idsBeingMoved = itemsInDrag.map(itemInDrag =>
                !ignoreReplanned &&
                itemInDrag.occurrence.isoPlannedDate &&
                itemInDrag.occurrence.isoPlannedDate !== itemInDrag.occurrence.isoDueDate
                    ? itemInDrag.occurrence._id
                    : itemInDrag.job._id
            );
            let count = globalJobOrderArray.length;

            // If each item is in the list of items to remove, remove it.
            let moveToIndex =
                idsBeingMoved.indexOf(insertAtId) > -1
                    ? idsBeingMoved.map(idBeingMoved => globalJobOrderArray.indexOf(idBeingMoved)).sort((x, y) => x - y)[0]
                    : -1;

            while (count--) {
                if (idsBeingMoved.indexOf(globalJobOrderArray[count]) === -1) continue;
                globalJobOrderArray.splice(count, 1);
            }

            if (moveToIndex === -1) {
                moveToIndex = globalJobOrderArray.indexOf(insertAtId);
                if (moveToIndex === -1) {
                    insertAtId = insertAtItem.job._id;
                    moveToIndex = globalJobOrderArray.indexOf(insertAtId);
                }
            }

            if (moveToIndex === -1) {
                Logger.info('*** target job index missing... rebuilding ***');
                let updated = false;
                // WTF? Check order data is valid with all jobs in it at least
                for (const customer of Data.all<Customer>('customers')) {
                    for (const jobId in customer.jobs || {}) {
                        if (globalJobOrderArray.indexOf(jobId) === -1) {
                            globalJobOrderArray.unshift(jobId);
                            updated = true;
                        }
                    }
                }

                if (globalJobOrderArray.indexOf(insertAtId) === -1) throw '';

                if (updated) {
                    // Lets retry
                    ScheduleService.updateListPlannedOrderInternal(
                        insertAtIndex,
                        itemsList,
                        itemsInDrag,
                        globalJobOrderArray,
                        ignoreReplanned
                    );
                }
                return;
            }

            moveToIndex += addAfterItem ? 1 : 0;

            if (moveToIndex > -1) globalJobOrderArray.splice(moveToIndex, 0, ...idsBeingMoved);
        } catch (error) {
            Logger.debug(`Failed to sort the job occurrences`, error);
            new NotifyUserMessage(<TranslationKey>`Unable to re-order, ${error}`);
        }
    }

    public static async checkForDuplicates(scheduleItems: Array<ScheduleItem>, targetDate: string) {
        for (const scheduleItem of scheduleItems) {
            if (shouldLetThreadIn(scheduleItem, scheduleItems, 20)) await wait(1);
            const occurrencePlannedDate = scheduleItem.occurrence.isoPlannedDate || scheduleItem.occurrence.isoDueDate;
            if (occurrencePlannedDate !== targetDate) {
                const duplicates = await JobOccurrenceService.checkForDuplicateOccurrences(scheduleItem.occurrence, targetDate);
                if (duplicates) {
                    new LoaderEvent(false);
                    await new Prompt('prompts.job-already-scheduled-title', 'prompts.job-already-scheduled-text', {
                        cancelLabel: '',
                        localisationParams: { address: scheduleItem.addressDescription || '' },
                    }).show();
                    return true;
                }
            }
        }
        return false;
    }

    public static async completeScheduleItems(
        scheduleItems: Array<ScheduleItem>,
        payment?: Transaction,
        completionDateInfo?: Awaited<ReturnType<(typeof ScheduleService)['getConfirmedDoneDate']>>
    ) {
        scheduleItems = scheduleItems.slice();
        const userId = RethinkDbAuthClient.session && UserService.getUser(RethinkDbAuthClient.session.email)?._id;
        const confirmedCompletedDateInfo = completionDateInfo || (await ScheduleService.getConfirmedDoneDate(scheduleItems));
        if (!confirmedCompletedDateInfo || !confirmedCompletedDateInfo.confirmedDate) return false;

        const canCompleteAutomatically = this.canCompleteScheduleItemsAutomatically(scheduleItems);

        if (!canCompleteAutomatically) {
            new LoaderEvent(false);
            if (
                !(await new Prompt(
                    'Manual Payment Confirmation' as TranslationKey,
                    ('One or more of these customers has automatic payment on invoice enabled ' +
                        'but your system settings are "always prompt before taking payments", ' +
                        'if you continue you will need to manually take the GoCardless payments for these customers.') as TranslationKey,
                    { cancelLabel: 'general.cancel', okLabel: 'Continue' as TranslationKey }
                ).show())
            )
                return false;
        }
        new LoaderEvent(true, true);
        if (await this.checkForDuplicates(scheduleItems, confirmedCompletedDateInfo.confirmedDate)) {
            return false;
        }

        let assignedToTeams = false;
        if (ApplicationState.multiUserEnabled) {
            const reassignIfTeamAssigned = ApplicationState.getSetting<boolean>('global.jobs.reassign-if-team-assigned', false);

            let assignedToOthers = false;
            if (userId) {
                for (const si of scheduleItems) {
                    if (AssignmentService.isUnassigned(si.occurrence)) continue;

                    const isAssignedToUser = AssignmentService.isAssigned(si.occurrence, userId, true);
                    if (isAssignedToUser && reassignIfTeamAssigned) {
                        assignedToTeams = assignedToTeams || AssignmentService.isAssignedToTeam(si.occurrence);
                    }
                    assignedToOthers = assignedToOthers || !isAssignedToUser;
                }
            }

            if (assignedToOthers) {
                new LoaderEvent(false);

                const question: TranslationKey =
                    scheduleItems.length <= 1
                        ? 'assignment.assigned-to-another-user-single'
                        : 'assignment.assigned-to-another-user-multiple';

                const response = await new Prompt('general.confirm', <TranslationKey>question, {
                    cancelLabel: 'general.cancel',
                    okLabel: 'general.continue',
                }).show();
                if (!response) return false;
            }
        }
        const completedItems: Array<ScheduleItem> = [];
        for (const scheduleItem of scheduleItems) {
            if (shouldLetThreadIn(scheduleItem, scheduleItems, 20)) {
                new LoaderEvent(true, true, 'loader.index-of-schedule-items-marked-as-done', undefined, {
                    index: (scheduleItems.indexOf(scheduleItem) + 1).toString(),
                    scheduleItems: scheduleItems.length.toString(),
                });
                await wait(10);
            }

            const reassignToSelf = assignedToTeams && ApplicationState.getSetting<boolean>('global.jobs.reassign-if-team-assigned', false);

            const options = [
                scheduleItem,
                confirmedCompletedDateInfo.confirmedDate,
                confirmedCompletedDateInfo.adjustFutureSchedule,
                scheduleItems.length > 1,
                reassignToSelf ? userId : undefined,
            ] as const;

            const completed = await ScheduleService.completeScheduleItem(...options);
            if (completed) completedItems.push(scheduleItem);
        }

        await ScheduleService.runAutomations(completedItems, payment);
        new LoaderEvent(false);
        if (completedItems.length) {
            requestAnimationFrame(() => {
                new ScheduleActionEvent('action-done');
                new NotifyUserMessage(<TranslationKey>`${completedItems.length} appointments marked as done successfully.`);
            });

            for (const scheduleItem of scheduleItems) ScheduleItem.refresh(scheduleItem);

            return true;
        }
        return false;
    }

    private static async enforceRequiredTags(occurrence: JobOccurrence, multiple: boolean): Promise<boolean> {
        if (!ApplicationState.features.ServiceTagging) return true;
        const requiresTags = occurrence.services.some(service => service.requiresTagging && (service.quantity || 0) > 0);
        if (!requiresTags) return true;

        const id = JobOccurrenceTagInstances.getId(occurrence._id);
        const jobOccurrenceTagInstances = Data.get<JobOccurrenceTagInstances>(id);
        if (!jobOccurrenceTagInstances) {
            if (!multiple) {
                new LoaderEvent(false);

                const serviceDialog = new JobPricingDialog(occurrence);
                await serviceDialog.show();

                const tagDialog = new JobOccurrenceTags(occurrence);
                await tagDialog.show();
                if (!tagDialog.cancelled) return true;
            }
            new NotifyUserMessage('services.missing-required-tags-multiple');
            return false;
        }

        const tagInstancesCollection = JobOccurrenceTagInstances.getTags(jobOccurrenceTagInstances);
        for (const service of occurrence.services) {
            if (!service.requiresTagging) continue;

            const tagInstances = tagInstancesCollection.find(x => x.serviceId === service._id);
            const requiredTagCount = service.requiresTagging === 'service' ? 1 : service.quantity || 0;
            if (tagInstances?.tagIds.length !== requiredTagCount) {
                if (!multiple) {
                    new LoaderEvent(false);

                    const serviceDialog = new JobPricingDialog(occurrence);
                    await serviceDialog.show();

                    const tagDialog = new JobOccurrenceTags(occurrence);
                    await tagDialog.show();
                    if (!tagDialog.cancelled) continue;
                }
                new NotifyUserMessage('services.missing-required-tags-multiple');
                return false;
            }
        }
        return true;
    }

    private static async completeScheduleItem(
        scheduleItem: ScheduleItem,
        confirmedDate: string,
        adjustSchedule: boolean,
        multiple: boolean,
        reassignTo?: string
    ): Promise<boolean> {
        try {
            if (!(await ScheduleService.enforceRequiredTags(scheduleItem.occurrence, multiple))) {
                new Prompt('general.error', 'services.missing-required-tags', { cancelLabel: '' }).show();
                return false;
            }
            const checkedAndSigned = await ScheduleService.checkAndSign(scheduleItem);

            if (!checkedAndSigned) {
                new LoaderEvent(false);
                await new Prompt('general.signature-required', 'error.message-cannot-complete-job-without-signature', {
                    cancelLabel: '',
                }).show();
                return false;
            }
            const occurrence = Utilities.copyObject(scheduleItem.occurrence);
            if (scheduleItem.isTimeTracked()) {
                const timerStopped = await TimerService.stopTimerOnCompletion(occurrence);
                if (!timerStopped) return false;
            }

            const job = Utilities.copyObject<Job>(scheduleItem.job);
            if (job.isQuote) {
                job.isQuote = false;
                await JobService.addOrUpdateJob(scheduleItem.customer._id, job, undefined);
            }

            // if there is a running timer, prompt user to stop it in order to complete job

            // clear concreted overdues if they exist, projection will hide the overdues
            const resetJobNotDones = ApplicationState.getSetting('global.jobs.reset-occurrences-on-done', false);
            if (resetJobNotDones) await JobOccurrenceService.deleteConcreteOverdueJobOccurrences(job, confirmedDate);

            const occurrencePlannedDate = occurrence.isoPlannedDate || occurrence.isoDueDate;

            if (reassignTo) {
                await AssignmentService.assign(occurrence, reassignTo, false, true);
            }

            const notify: DataChangeNotificationType = multiple ? 'lazy' : 'immediate';

            if (occurrencePlannedDate !== confirmedDate) {
                // replan occurrence to date before completing
                const replannedOccurrence = await JobOccurrenceService.replanOccurrence(
                    occurrence,
                    confirmedDate,
                    multiple,
                    adjustSchedule,
                    true
                );
                if (!replannedOccurrence) return false;

                replannedOccurrence.status = JobOccurrenceStatus.Done;
                replannedOccurrence.isoCompletedDate = confirmedDate;
                await AssignmentService.concreteAssigneesOnOccurrence(
                    replannedOccurrence._id,
                    AssignmentService.getAssignees(replannedOccurrence, false)
                );
                await Data.put(replannedOccurrence, true, notify);

                scheduleItem.updateOccurrence(replannedOccurrence);
                ScheduleItem.refresh(scheduleItem);
            } else {
                occurrence.status = JobOccurrenceStatus.Done;
                occurrence.isoCompletedDate = confirmedDate;
                await AssignmentService.concreteAssigneesOnOccurrence(occurrence._id, AssignmentService.getAssignees(occurrence, false));
                await Data.put(occurrence, true, notify);
            }
        } catch (error) {
            Logger.error('error completing schedule item', error);
            return false;
        }

        return true;
    }

    static canCompleteScheduleItemsAutomatically(scheduleItems: ScheduleItem[]) {
        if (ApplicationState.account.invoiceSettings.autoChargeInvoiceMinusCredit) return true;
        for (const scheduleItem of scheduleItems) {
            if (
                scheduleItem.customer.autoInvoiceMethod === 'auto' &&
                !scheduleItem.customer.automaticPaymentMethod &&
                scheduleItem.customer.takePaymentOnInvoiced
            )
                return false;
        }
        return true;
    }

    private static async checkAndSign(scheduleItem: ScheduleItem) {
        if (
            scheduleItem.job.requireSignature === true ||
            (scheduleItem.job.requireSignature === undefined &&
                (scheduleItem.customer.requireSignature === true ||
                    (scheduleItem.customer.requireSignature === undefined && ApplicationState.account.requireSignature)))
        ) {
            return await ApplicationState.signObject(
                scheduleItem.occurrence,
                `Sign for ${scheduleItem.serviceList} at ${scheduleItem.addressDescription}`,
                false
            );
        }
        return true;
    }

    public static async runAutomations(scheduleItems: Array<ScheduleItem>, payment?: Transaction, allowTriggerPayments = true) {
        const failed = [] as Array<{
            step: string;
            customerId: string;
            jobId: string;
            occurrenceId: string;
            isConcrete: boolean;
            status?: JobOccurrenceStatus;
            autoInvoice: boolean;
        }>;

        for (const scheduleItem of scheduleItems) {
            if (shouldLetThreadIn(scheduleItem, scheduleItems, 20)) {
                new LoaderEvent(true, true, 'loader.index-of-schedule-items-processed', undefined, {
                    index: (scheduleItems.indexOf(scheduleItem) + 1).toString(),
                    scheduleItems: scheduleItems.length.toString(),
                });
                await wait(1);
            }

            const createInvoicesWhenZero = ApplicationState.getSetting('global.invoice-auto-create-invoices-for-zero-value-jobs', false);
            if ((scheduleItem.price === 0 && !createInvoicesWhenZero) || scheduleItem.job.isQuote) continue;

            const autoInvoice =
                (scheduleItem.customer.autoInvoiceMethod !== 'manual' &&
                    ApplicationState.account.invoiceSettings.defaultInvoiceGenerationMethod !== 'manual') ||
                scheduleItem.customer.autoInvoiceMethod === 'auto';

            //WTF? we want to check job has been saved and is ready
            const occurrence = Data.get<JobOccurrence>(scheduleItem.occurrence._id);
            if (!occurrence || occurrence.status !== JobOccurrenceStatus.Done) {
                failed.push({
                    step: 'occurrence check failed',
                    customerId: scheduleItem.customer._id,
                    jobId: scheduleItem.job._id,
                    occurrenceId: scheduleItem.occurrence._id,
                    isConcrete: !!occurrence,
                    status: occurrence?.status,
                    autoInvoice,
                });
                continue;
            }

            if (autoInvoice)
                await InvoiceService.autoCreateInvoiceForOccurrence(
                    scheduleItem.customer,
                    occurrence,
                    scheduleItems.length > 1,
                    payment,
                    allowTriggerPayments
                ).catch(error => {
                    failed.push({
                        step: `invoice creation failed ${error?.message || error?.toString() || ''}`,
                        customerId: scheduleItem.customer._id,
                        jobId: scheduleItem.job._id,
                        occurrenceId: scheduleItem.occurrence._id,
                        isConcrete: !!occurrence,
                        status: occurrence?.status,
                        autoInvoice,
                    });
                });
        }

        if (failed.length) {
            const details = { paymentTransactionId: payment?._id, allowTriggerPayments, failed };
            Logger.error(`Failed to run automations for ${failed.length} of ${scheduleItems} jobs`, details, 1);
            new NotifyUserMessage(<TranslationKey>`Failed to run automations for ${failed.length} jobs`);
        }
    }

    public static getJobOrderData() {
        const jobOrderDatas = Data.all<JobOrderData>('joborderdata', x => x.type === 'global-day')
            .slice()
            .sort(sortByCreatedDateAsc);

        return jobOrderDatas[0];
    }
    public static async addJobToOrderData(jobId: string) {
        const jobOrderData = ScheduleService.getJobOrderData();
        if (!jobOrderData) return;

        jobOrderData.data.unshift(jobId);

        // WTF? To cleanup job Data;

        await SqueegeeTools.cleanUpJobOrderData(jobOrderData);
    }

    public static async getConfirmedDoneDate(
        scheduleItems: Array<ScheduleItem>
    ): Promise<{ confirmedDate: string; adjustFutureSchedule: boolean } | undefined> {
        const plannedDate = scheduleItems[0].occurrence.isoPlannedDate || scheduleItems[0].occurrence.isoDueDate;
        const today = moment().format('YYYY-MM-DD');
        if (plannedDate !== today) {
            new LoaderEvent(false);
            const completedDateDialog = new ConfirmDayCompletedDialog(plannedDate, scheduleItems);
            const completedDateInfo = await completedDateDialog.show();
            if (!completedDateDialog.cancelled) {
                return completedDateInfo;
            }
        } else {
            return { confirmedDate: plannedDate, adjustFutureSchedule: false };
        }
    }

    public static getScheduleItemForJobOccurrenceId(id: string): ScheduleItem | undefined {
        const occurrence = Data.get<JobOccurrence>(id);
        if (occurrence) {
            return ScheduleService.getScheduleItemForJobOccurrence(occurrence);
        }
    }

    public static getScheduleItemForJobOccurrence(occurrence: JobOccurrence): ScheduleItem | undefined {
        if (!occurrence.customerId) return;

        const customer = Data.get<Customer>(occurrence.customerId);
        if (customer) {
            const job = customer.jobs[occurrence.jobId];
            if (job) return new ScheduleItem(job, customer, occurrence);
        }
    }

    public static getAllActiveJobsAsScheduleItems() {
        const jobs = JobService.getActiveJobs();
        const itemsDue = ScheduleService.getScheduleForJobs(jobs, moment(), undefined, [JobOccurrenceStatus.NotDone], 5);
        const nextJob: Record<string, ScheduleItem> = {};
        for (const item of itemsDue) {
            if (nextJob[item.job._id] && nextJob[item.job._id].date <= item.date) continue;
            nextJob[item.job._id] = item;
        }

        for (const job of jobs) {
            if (nextJob[job._id]) continue;
            const cust = Data.get<Customer>(job.customerId);
            if (!cust) continue;

            if (job.frequencyType !== FrequencyType.NoneRecurring && job.frequencyType !== FrequencyType.ExternalCalendar) {
                new NotifyUserMessage('problem.cannot-find-next-occurrence', { job: job.location?.addressDescription || '' });
            }

            const dummyOccurrence = new JobOccurrence(job, '', JobOccurrenceStatus.NotScheduled);
            nextJob[job._id] = new ScheduleItem(job, cust, dummyOccurrence);
        }

        return Object.values(nextJob);
    }

    public static async areYouSure(description: TranslationKey): Promise<boolean> {
        const dialog = prompt('general.confirm', description, {
            cancelLabel: 'general.cancel',
            okLabel: 'general.continue',
        });

        await dialog.show();
        const sure = !dialog.cancelled;

        Logger.info(`User is ${sure ? 'sure' : 'not sure'} about the action.`);

        return sure;
    }

    public static scheduledMessageUpdateTokens(
        templateText: string,
        sendAtSecondsTimestamp: number,
        model: ScheduleItemMessageModel,
        customerId?: string
    ) {
        const updatedModel = model;
        const now = moment(sendAtSecondsTimestamp * 1000);
        const scheduledDate = moment(model.scheduledDate);

        const tokensInUse = getStandardMessageTokensInUse(templateText);

        // Calculate days difference, round up for partial days
        const daysFromNow = Math.ceil(scheduledDate.diff(now, 'days', true));

        let dateFromNow;
        if (daysFromNow === 1) {
            dateFromNow = 'tomorrow';
        } else if (daysFromNow > 1) {
            dateFromNow = `in ${daysFromNow} days`;
        } else {
            dateFromNow = 'today';
        }
        updatedModel.dateFromNow = dateFromNow;

        if (tokensInUse?.nextDate && customerId) {
            const nextScheduleItem = ScheduleService.getNextScheduleItemForCustomer(customerId, scheduledDate.format('YYYY-MM-DD'));
            const nextDate = nextScheduleItem ? nextScheduleItem.formattedDate : ApplicationState.localise('general.unknown');
            updatedModel.nextDate = nextDate;
        }

        return replaceMessageTokensWithModelValues({
            model: updatedModel,
            message: templateText,
            options: { yesLabel: t('general.yes'), noLabel: t('general.no') },
        });
    }
}
