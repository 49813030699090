import { Box } from '@mui/material';
import type { VirtualInfiniteLoaderRef } from '@nexdynamic/nex-ui-react';
import { VirtualInfiniteList, useIsSmallScreen } from '@nexdynamic/nex-ui-react';
import type { AuditEvent } from '@nexdynamic/squeegee-common';
import { useEffect, useRef, useState } from 'react';
import { Api } from '../../../Server/Api';
import LoadingScreen from '../../components/LoadingScreen';
import { AuditListItem } from '../components/AuditListItem';
import { AuditLoadingItem } from '../components/AuditLoadingItem';
import AuditTableHeader from '../components/AuditTableHeader';
import CenteredSpinner from '../components/CenteredSpinner';
import type { AuditEventsResponse } from '../types/AuditEventsResponse';

const Main = () => {
    const isMobile = useIsSmallScreen();

    const [loading, setLoading] = useState<boolean>(false);
    const [itemCount, setItemCount] = useState<number>(0);
    const [auditEntries, setAuditEntries] = useState<Array<AuditEvent>>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');

    useEffect(() => {
        setLoading(true);
        setAuditEntries([]);
        Api.post<AuditEventsResponse>(null, '/api/audit/events', {
            query: searchQuery,
            fromTimestamp: 0,
            stopIndex: 50,
        }).then(res => {
            setItemCount(res?.data.total || 0);
            setAuditEntries(res?.data.items || []);
            setLoading(false);
        });
    }, [searchQuery]);

    const handleSearch = (text: string) => {
        setSearchQuery(text);
    };

    const listRef = useRef<VirtualInfiniteLoaderRef>(null);
    const [expandedItems, setExpandedItems] = useState<Record<number, boolean>>({});

    const toggleExpandItem = (index: number) => {
        setExpandedItems(prevState => ({
            ...prevState,
            [index]: !prevState[index],
        }));

        // This forces the list to re-check the size of items
        listRef.current?.resetloadMoreItemsCache();
    };

    const renderEntry = (index: number) => {
        if (!isItemLoaded(index)) {
            return <AuditLoadingItem dense={isMobile} />;
        } else {
            return (
                <AuditListItem
                    entry={auditEntries[index]}
                    isExpanded={!!expandedItems[index]}
                    index={index}
                    dense={isMobile}
                    toggleExpandItem={toggleExpandItem}
                />
            );
        }
    };

    const getItemSize = (index: number) => {
        return expandedItems[index] ? 205 : 60;
    };

    const loadMoreItems = async (startIndex: number) => {
        setLoading(true);
        if (isItemLoaded(startIndex) || loading) {
            return;
        }
        return await Api.post<AuditEventsResponse>(null, '/api/audit/events', {
            query: searchQuery,
            fromTimestamp: auditEntries[startIndex - 1]?.timestamp || 0,
        }).then(res => {
            const total = res?.data.total || 0;
            setItemCount(total);
            const newEntries: Array<AuditEvent> = res?.data.items || [];
            const updatedEntries = [...auditEntries, ...newEntries].sort((a, b) => b.timestamp - a.timestamp);
            setAuditEntries(updatedEntries);
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        });
    };

    const isItemLoaded = (index: number) => auditEntries[index] !== undefined;

    return (
        <>
            <LoadingScreen open={false} message="This may take a while." />
            <Box
                sx={{
                    height: '100%',
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    p: 2,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        height: '100%',
                        border: 1,
                        borderColor: 'divider',
                        borderRadius: 1,
                        overflow: 'hidden',
                    }}
                >
                    <AuditTableHeader
                        handleSearch={handleSearch}
                        loadMore={loadMoreItems}
                        length={auditEntries.length}
                        isMobile={isMobile}
                    />
                    {loading && auditEntries.length === 0 ? (
                        <CenteredSpinner />
                    ) : (
                        <VirtualInfiniteList
                            ref={listRef}
                            itemCount={itemCount}
                            renderItem={renderEntry}
                            getItemSize={getItemSize}
                            loadMoreItems={loadMoreItems}
                            isItemLoaded={isItemLoaded}
                        />
                    )}
                </Box>
            </Box>
        </>
    );
};

export default Main;
