import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InfoIcon from '@mui/icons-material/Info';
import PaymentIcon from '@mui/icons-material/Payment';
import CriticalIcon from '@mui/icons-material/Report';
import WarningIcon from '@mui/icons-material/Warning';
import {
    Avatar,
    Card,
    CardContent,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Stack,
    Typography,
} from '@mui/material';
import { DialogPaper, DialogRoot, useIsSmallScreen } from '@nexdynamic/nex-ui-react';
import type { Transaction } from '@nexdynamic/squeegee-common';
import { TransactionType, type Customer } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../../../ApplicationState';
import { CustomerDialog } from '../../../Customers/Components/CustomerDialog';
import type {
    AureliaReactComponentDialogComponent,
    AureliaReactComponentDialogComponentStandardProps,
} from '../../../Dialogs/AureliaReactComponentDialogComponent';
import { prompt } from '../../../Dialogs/ReactDialogProvider';
import { InvoiceTransactionSummaryDialog } from '../../../Invoices/InvoiceTransactionSummaryDialog';
import { TransactionDialog } from '../../../Transactions/Components/TransactionDialog';
import { Utilities } from '../../../Utilities';
import { isDevMode } from '../../../isDevMode';
import useTranslation from '../../hooks/useTranslation';
import { calculateCustomerRisk } from '../customerRisk';
import type { PendingCustomer } from '../hooks/usePendingCustomers';
import { usePendingTransactions } from '../hooks/usePendingTransactions';
import { XsChip } from '../views/PendingMain';
import Price from './Price';

export type PendingCustomerDialogProps = {
    customer: PendingCustomer;
    paymentProvider: 'gocardless' | 'stripe';
};

type Props = AureliaReactComponentDialogComponentStandardProps<Customer> & PendingCustomerDialogProps;

const PendingCustomerDialog: AureliaReactComponentDialogComponent<Props> = ({ customer, paymentProvider }: Props) => {
    const { t } = useTranslation();

    const pendingTransactions = usePendingTransactions(customer).sort(
        (a, b) => moment(b.createdDate).unix() - moment(a.createdDate).unix()
    );

    const handleTransactionClick = (transaction: Transaction) => {
        switch (transaction.transactionType) {
            case TransactionType.Invoice:
                new InvoiceTransactionSummaryDialog(transaction).show();
                break;
            default:
                new TransactionDialog(transaction).show();
                break;
        }
    };

    const risk = calculateCustomerRisk(customer, pendingTransactions);

    const isSmallScreen = useIsSmallScreen();

    const getCriticalRiskText = () => {
        if (paymentProvider === 'gocardless') {
            if (customer.paymentProviderMetaData?.gocardless?.secondSignatureRequired) {
                return t('pending.gocardless-dual-signature-required');
            }
            if (customer.paymentProviderMetaData?.gocardless?.status !== 'active') {
                return t('pending.critical-risk-gocardless-long-text', {
                    paymentCount: pendingTransactions.length.toString(),
                    oldestDate: moment(pendingTransactions[pendingTransactions.length - 1].date).format('MMM Do YYYY'),
                });
            }
        }

        if (paymentProvider === 'stripe') {
            return t('pending.critical-risk-stripe-long-text', {
                paymentCount: pendingTransactions.length.toString(),
                oldestDate: moment(pendingTransactions[pendingTransactions.length - 1].date).format('MMM Do YYYY'),
            });
        }

        return '';
    };

    return (
        <DialogRoot>
            <Paper>
                <List>
                    <ListItem onClick={() => new CustomerDialog(customer).show()} disablePadding>
                        <ListItemButton>
                            <ListItemAvatar>
                                <Avatar sx={{ bgcolor: Utilities.textToColour(customer.name) }}>
                                    {Utilities.getAvatarTextFromName(customer.name)}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={customer.name} secondary={customer.address.addressDescription} />
                            <ChevronRightIcon sx={{ color: 'action.active', ml: 2 }} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Paper>
            {customer.risk.riskLevel === 'Critical' && (
                <Card>
                    <Stack direction="row" spacing={0.5} alignItems="center" px={2} pt={2}>
                        <CriticalIcon color="error" />
                        <Typography variant="h6">{t('pending.critical-risk')}</Typography>
                    </Stack>
                    <Divider sx={{ mx: 2 }} />
                    <CardContent>
                        <Typography variant="body2">
                            {getCriticalRiskText()}
                            <br />
                            <br />
                            {t('pending.critical-risk-advisory-text')}
                        </Typography>
                    </CardContent>
                </Card>
            )}
            <Card>
                <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" px={2} pt={2}>
                    <Stack spacing={1} direction="row" alignItems="center">
                        <Typography variant="h6">{t('pending.risk-factors')}</Typography>
                        <IconButton
                            size="small"
                            onClick={() =>
                                prompt(
                                    'pending.risk-factors-explanation-header',
                                    'pending.risk-factors-explanation-text',
                                    {
                                        cancelLabel: '',
                                    },
                                    { dialog: { fullScreen: isSmallScreen } }
                                ).show()
                            }
                        >
                            <InfoIcon />
                        </IconButton>
                    </Stack>
                    <XsChip
                        label={`${risk.riskLevel} Risk`}
                        color={
                            customer.risk.riskLevel === 'High' || customer.risk.riskLevel === 'Critical'
                                ? 'error'
                                : customer.risk.riskLevel === 'Medium'
                                ? 'warning'
                                : 'info'
                        }
                        icon={
                            customer.risk.riskLevel === 'Critical' ? (
                                <CriticalIcon />
                            ) : customer.risk.riskLevel === 'High' || customer.risk.riskLevel === 'Medium' ? (
                                <WarningIcon />
                            ) : (
                                <InfoIcon />
                            )
                        }
                        size="small"
                    />
                </Stack>
                <Divider sx={{ mx: 2 }} />
                <CardContent>
                    {risk.contributingFactors.map((factor, index) => (
                        <Typography key={index} variant="body2">
                            - {factor}
                        </Typography>
                    ))}
                    {risk.contributingFactors.length === 0 && (
                        <Typography variant="body2" color="text.secondary">
                            {t('pending.no-risk-factors')}
                        </Typography>
                    )}
                    {isDevMode() && (
                        <Typography variant="body2" color="text.secondary" textAlign="right">
                            Risk Score: {risk.riskScore}
                        </Typography>
                    )}
                </CardContent>
            </Card>

            <DialogPaper>
                <Stack direction="row" spacing={2} justifyContent={'space-between'} alignItems="center" px={2} pt={2}>
                    <Typography variant="h6">{t('pending.pending-transactions')}</Typography>
                    <Typography>
                        {t('general.total')}: {ApplicationState.currencySymbol()}
                        {Math.abs(pendingTransactions.reduce((acc, t) => acc + t.amount, 0)).toFixed(2)}
                    </Typography>
                </Stack>
                <Divider sx={{ mx: 2 }} />
                <List>
                    {pendingTransactions.map((transaction, index) => (
                        <ListItem key={transaction._id} disablePadding divider={index < pendingTransactions.length - 1}>
                            <ListItemButton onClick={() => handleTransactionClick(transaction)}>
                                <ListItemIcon sx={{ minWidth: 36 }}>
                                    <PaymentIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={`${
                                        transaction.paymentDetails?.paymentProvider === 'gocardless'
                                            ? 'GoCardless'
                                            : transaction.paymentDetails?.paymentProvider === 'stripe'
                                            ? 'Stripe'
                                            : 'Unknown'
                                    } Payment`}
                                    secondary={
                                        <>
                                            <Typography variant="body2" color="text.secondary">
                                                {transaction.description}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {moment(transaction.date).format('MMM Do YYYY, h:mma')}
                                            </Typography>
                                        </>
                                    }
                                />
                                <Stack ml={1} alignItems="flex-end">
                                    <Price price={transaction.amount} />
                                    <XsChip
                                        label={t('general.pending').charAt(0).toUpperCase() + t('general.pending').slice(1)}
                                        color="warning"
                                        size="small"
                                        sx={{ width: 'fit-content' }}
                                    />
                                </Stack>
                                <ChevronRightIcon sx={{ color: 'action.active', ml: 2 }} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                    {pendingTransactions.length === 0 && (
                        <Typography variant="body2" color="text.secondary" sx={{ p: 2 }}>
                            No pending transactions
                        </Typography>
                    )}
                </List>
            </DialogPaper>
        </DialogRoot>
    );
};

export default PendingCustomerDialog;
