import { Box, Button, Card, CardContent, CardHeader, Divider, Link, List, ListItem, Switch, Typography } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useIsSmallScreen } from '@nexdynamic/nex-ui-react';
import type { TranslationKey } from '@nexdynamic/squeegee-common';
import type { PortalConfig, TextKeys } from '@nexdynamic/squeegee-portal-common';
import { portalComponentSettingsDefaults } from '@nexdynamic/squeegee-portal-common';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { ApplicationState } from '../../../../ApplicationState';
import type { AureliaReactComponentDialogComponent } from '../../../../Dialogs/AureliaReactComponentDialogComponent';
import { NotifyUserMessage } from '../../../../Notifications/NotifyUserMessage';
import { getPortalTextDialog } from '../../getPortalTextDialog';
import { useDirectoryEntryDetails } from '../../useDirectoryEntryDetails';
import { clearPortalComponentSetting } from './clearPortalComponentSetting';
import { setPortalComponentSetting } from './setPortalComponentSetting';

export const PortalChatSettingsEditor: AureliaReactComponentDialogComponent<any> = () => {
    const parts = useDirectoryEntryDetails();

    const textKeys = parts.textkeys;

    const messageForOutOfHours = textKeys?.['out-of-hours'];

    const settingDefaults = portalComponentSettingsDefaults['chat'];

    type SettingType = typeof settingDefaults;
    const chatComponentSettings = ApplicationState.getSetting<SettingType>('portal.component-chat', settingDefaults);
    const [chatSettings, setChatSettings] = useState<SettingType>(chatComponentSettings);

    const isSmallScreen = useIsSmallScreen();
    const directionCalculation = isSmallScreen ? 'column' : 'flex';

    const daysOfWeek = Object.entries(chatSettings.workingDay).map(([key, value]) => {
        return { day: key, startEnd: value };
    });

    const textDialog = async () => {
        if (!parts.directory) return;

        const dialog = getPortalTextDialog(parts.directory, 'out-of-hours');
        const textSettings = await dialog.show();
        if (dialog.cancelled) return;

        if (!parts.directory.config) parts.directory.config = {} as PortalConfig;
        parts.directory.config.text = textSettings.text as typeof TextKeys;
        parts.directory.config.textDebug = textSettings.textDebug;
        await parts.saveDirectory(parts.directory);
        setChatSettings({
            ...chatSettings,
            outOfHoursMessage: textSettings.text['out-of-hours'] as (typeof TextKeys)['out-of-hours'],
        });

        new NotifyUserMessage('portal.chat.out-of-hours-message-saved');
    };

    const view = useMemo(() => {
        if (!chatSettings.workingDay) return null;
        if (!chatSettings.useWorkingHours) return null;

        return (
            <Card sx={{ m: 2 }} variant="outlined">
                <CardHeader
                    title={ApplicationState.localise('portal.chat.opening-times')}
                    subheader={ApplicationState.localise('portal.chat.opening-times-description')}
                />
                <Typography sx={{ m: 2, mt: 0 }}>
                    <Link color="primary" onClick={() => textDialog()} sx={{ cursor: 'pointer' }}>
                        {ApplicationState.localise('component-settings.your-out-of-hours-message')}
                    </Link>
                    : {messageForOutOfHours}
                </Typography>
                <Divider />
                <CardContent>
                    <List>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            {daysOfWeek.map((day, index) => {
                                const formattedDay = ApplicationState.localise(`day.${day.day}` as TranslationKey);
                                return (
                                    <ListItem
                                        key={index}
                                        sx={{ display: 'flex', flexDirection: directionCalculation, justifyContent: 'space-between' }}
                                    >
                                        <Box>
                                            <Typography variant="h6" gutterBottom>
                                                {formattedDay}
                                            </Typography>
                                            <Box sx={{ display: 'flex', gap: 1 }}>
                                                <TimePicker
                                                    value={moment(
                                                        chatSettings.workingDay[day.day as keyof typeof chatSettings.workingDay].start
                                                    )}
                                                    onChange={e =>
                                                        setChatSettings({
                                                            useWorkingHours: chatSettings.useWorkingHours,
                                                            workingDay: {
                                                                ...chatSettings.workingDay,
                                                                [day.day]: {
                                                                    start: moment(e).format('YYYY-MM-DDTHH:mm'),
                                                                    end: moment(day.startEnd.end).format('YYYY-MM-DDTHH:mm'),
                                                                },
                                                            },
                                                            outOfHoursMessage: chatSettings.outOfHoursMessage,
                                                        })
                                                    }
                                                />
                                                <TimePicker
                                                    value={moment(
                                                        chatSettings.workingDay[day.day as keyof typeof chatSettings.workingDay].end
                                                    )}
                                                    onChange={e =>
                                                        setChatSettings({
                                                            useWorkingHours: chatSettings.useWorkingHours,
                                                            workingDay: {
                                                                ...chatSettings.workingDay,
                                                                [day.day]: {
                                                                    start: moment(day.startEnd.start).format('YYYY-MM-DDTHH:mm'),
                                                                    end: moment(e).format('YYYY-MM-DDTHH:mm'),
                                                                },
                                                            },
                                                            outOfHoursMessage: chatSettings.outOfHoursMessage,
                                                        })
                                                    }
                                                />
                                            </Box>
                                        </Box>
                                    </ListItem>
                                );
                            })}
                        </LocalizationProvider>
                    </List>
                </CardContent>
            </Card>
        );
    }, [chatSettings]);

    if (!parts) return <>Error finding directory</>;
    return (
        <>
            <Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: 2 }}>
                    <Button variant="contained" color="error" onClick={async () => await clearPortalComponentSetting('chat')}>
                        {ApplicationState.localise('general.clear-settings')}
                    </Button>
                    <Button variant="contained" onClick={() => setPortalComponentSetting('chat', chatSettings)}>
                        {ApplicationState.localise('general.save-button')}
                    </Button>
                </Box>

                <Card sx={{ m: 2 }} variant="outlined">
                    <CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                                {ApplicationState.localise('portal-chat.use-opening-times')}
                            </Typography>
                            <Switch
                                checked={chatSettings.useWorkingHours}
                                onChange={e =>
                                    setChatSettings({
                                        useWorkingHours: e.target.checked,
                                        workingDay: chatSettings.workingDay,
                                        outOfHoursMessage: chatSettings.outOfHoursMessage,
                                    })
                                }
                                color="secondary"
                            />
                        </Box>
                    </CardContent>
                </Card>
                {view}
            </Box>
        </>
    );
};
