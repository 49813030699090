import BackupIcon from '@mui/icons-material/BackupRounded';
import BlockIcon from '@mui/icons-material/BlockRounded';
import DeletedIcon from '@mui/icons-material/DeleteForeverRounded';
import ExpandIcon from '@mui/icons-material/ExpandMoreRounded';
import HelpIcon from '@mui/icons-material/HelpRounded';
import InfoIcon from '@mui/icons-material/InfoRounded';
import StartIcon from '@mui/icons-material/PlayArrowRounded';
import TestIcon from '@mui/icons-material/ScienceRounded';
import DatabaseIcon from '@mui/icons-material/StorageRounded';
import RejectedIcon from '@mui/icons-material/ThumbDownRounded';
import ApprovedIcon from '@mui/icons-material/ThumbUpRounded';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Box,
    Button,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    useTheme,
} from '@mui/material';
import type { AuditEvent, AuditEventAction } from '@nexdynamic/squeegee-common';
import { StoredObjectResourceTypeFriendlyText } from '@nexdynamic/squeegee-common';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import calendar from 'dayjs/plugin/calendar';
import { ApplicationState } from '../../../ApplicationState';
import { CustomerService } from '../../../Customers/CustomerService';
import { UserService } from '../../../Users/UserService';
dayjs.extend(advancedFormat);
dayjs.extend(calendar);

export const AuditListItem = ({
    entry,
    isExpanded,
    index,
    toggleExpandItem,
}: {
    entry: AuditEvent;
    isExpanded: boolean;
    dense?: boolean;
    index: number;
    toggleExpandItem: (index: number) => void;
}) => {
    const selectIcon = (action: AuditEventAction) => auditEventActionIcons[action] || auditEventActionIcons.default;

    const getUserNameAndAvatar = (email: string) => {
        const users = UserService.getUsers();
        const user = users.find(user => user.email === email);
        return { name: user?.name || email, avatar: user?.avatar || '' };
    };

    const getAction = (action: AuditEventAction) => {
        return action.replace(/-/g, ' ');
    };

    const getSubject = (auditEvent: AuditEvent) => {
        if (!auditEvent.subject) return '';

        const relatedSubjects = auditEvent.relatedSubjects;
        const text = StoredObjectResourceTypeFriendlyText[auditEvent.subject[1] as keyof typeof StoredObjectResourceTypeFriendlyText];

        if (text) return relatedSubjects?.some(x => x[1] === auditEvent.subject[1]) ? text[1] : text[0];

        return auditEvent.subject[0] + ': ' + auditEvent.subject[1];
    };

    const handleRestoreCustomer = () => {
        if (entry?.subject?.[1] !== 'customers') return;

        CustomerService.restoreDeletedCustomers(entry.subject[0], true);
    };

    const theme = useTheme();

    const who = !entry.createdBy ? 'unknown user' : getUserNameAndAvatar(entry.createdBy).name;
    const action = getAction(entry.action);
    const subject = getSubject(entry);

    return (
        <Accordion
            sx={{
                'borderTop': `1px solid ${theme.palette.divider}`,
                '&:not(:last-child)': {
                    borderBottom: 0,
                },
                '&:before': {
                    display: 'none',
                },
                'background': 'background.paper',
                'height': '100%',
                'display': 'flex',
                'flexDirection': 'column',
                '& .MuiCollapse-root': {
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    justifyContent: 'center',
                    background: 'background.paper',
                    borderTop: `1px solid ${theme.palette.divider}`,
                },
            }}
            expanded={isExpanded}
            onChange={() => {
                toggleExpandItem(index);
            }}
            disableGutters
            square
        >
            <AccordionSummary sx={{ height: 60 }} expandIcon={<ExpandIcon />}>
                <ListItem sx={{ height: 60 }} disablePadding>
                    <ListItemIcon sx={{ minWidth: 32 }}>{selectIcon(entry?.action)}</ListItemIcon>
                    {entry.createdBy && <Avatar sx={{ width: 32, height: 32, mr: 1 }} src={getUserNameAndAvatar(entry.createdBy).avatar} />}
                    <ListItemText
                        primary={`${subject} ${action} (${who})`}
                        secondary={dayjs(entry.timestamp)
                            // TODO: fix locale for calendar plugin
                            .calendar(null, {
                                sameDay: '[Today at] H:mma',
                                lastDay: '[Yesterday at] H:mma',
                                lastWeek: '[Last] dddd [at] H:mma',
                                sameElse: 'DD/MM/YYYY',
                            })
                            .toString()}
                    ></ListItemText>
                </ListItem>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    justifyContent: 'center',
                    p: 2,
                }}
            >
                <Typography fontSize="inherit">
                    <b>Time:</b> {dayjs(entry?.timestamp).format('MMM Do, HH:mm:ss').toString()}
                </Typography>
                <Typography fontSize="inherit">
                    <b>User:</b> {entry?.createdBy}
                </Typography>
                <Typography fontSize="inherit">
                    <b>Action:</b> {action}
                </Typography>
                {subject && (
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Typography fontSize="inherit">
                            <b>Subject:</b> {subject}
                        </Typography>
                    </Box>
                )}
                {entry?.actionDetailsSummary ? (
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Typography fontSize="inherit">
                            <b>Summary:</b> {entry?.actionDetailsSummary}
                        </Typography>
                    </Box>
                ) : null}
                {entry?.actionDetailsDescription ? (
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Typography fontSize="inherit">
                            <b>Details:</b> {entry?.actionDetailsDescription}
                        </Typography>
                    </Box>
                ) : null}
                <Box sx={{ mt: 0.5 }}>
                    {ApplicationState.stateFlags.devMode ? (
                        <Button
                            onClick={() => console.log(entry)}
                            variant="contained"
                            size="small"
                            color="secondary"
                            disableElevation
                            sx={{ mr: 1 }}
                        >
                            Log Audit Entry to Console
                        </Button>
                    ) : null}
                    {entry?.subject?.[1] === 'customers' && !entry.actionDetailsSummary ? (
                        <Button onClick={handleRestoreCustomer} variant="contained" size="small" color="primary" disableElevation>
                            Restore
                        </Button>
                    ) : null}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export const auditEventActionIcons: { [key in AuditEventAction | 'default']: JSX.Element } = {
    'delete': <DeletedIcon />,
    'transaction-void': <BlockIcon />,
    'test': <TestIcon />,
    'startup': <StartIcon />,
    'bulk-delete': <DeletedIcon />,
    'database-usage': <DatabaseIcon />,
    'sms-name-approved': <ApprovedIcon />,
    'sms-name-rejected': <RejectedIcon />,
    'support-auth-start': <HelpIcon />,
    'backup': <BackupIcon />,
    'default': <InfoIcon />,
};
