import CreateBackupIcon from '@mui/icons-material/Backup';
import { Button, CircularProgress, Collapse, Divider, Typography } from '@mui/material';
import type { TransitionProps } from '@mui/material/transitions';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import type { TreeItemProps } from '@mui/x-tree-view/TreeItem';
import { TreeItem as MuiTreeItem } from '@mui/x-tree-view/TreeItem';
import { DialogPaper, DialogRoot } from '@nexdynamic/nex-ui-react';
import { animated, useSpring } from '@react-spring/web';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { forwardRef, useState } from 'react';
import { ApplicationState } from '../../../ApplicationState';
import { backupServer } from '../../../Data/backupServer';
import { createBackup, restoreBackup } from '../../../Data/createBackup';
import { RethinkDbAuthClient } from '../../../Server/RethinkDbAuthClient';
import { Utilities } from '../../../Utilities';
import useStateFlags from '../../hooks/useStateFlags';
import useTranslation from '../../hooks/useTranslation';
import { BackupListItemComponent } from './BackupListItemComponent';
import { useBackups } from './hooks/useBackups';
import { loadBackupIntoMemory } from './loadBackupIntoMemory';

dayjs.extend(advancedFormat);

const TransitionComponent = (props: TransitionProps) => {
    const style = useSpring({
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
        },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
};

const TreeItem = forwardRef((props: TreeItemProps, ref: React.Ref<HTMLLIElement>) => (
    <MuiTreeItem {...props} slots={{ groupTransition: TransitionComponent, ...props.slots }} ref={ref} />
));

const ManageBackupsDialogComponent = () => {
    const { mostRecentBackup, backupTree, refresh } = useBackups();
    const [backedUpThisSession, setBackedUpThisSession] = useState(false);
    const { t } = useTranslation();

    const restoreBackupUi = async (backup: { id: string; date: string }) => {
        restoreBackup(backup);
    };

    const downloadBackup = async (backup: { id: string }) => {
        if (!RethinkDbAuthClient.session) return;

        const { key, value } = RethinkDbAuthClient.session;
        const oneTimeLink = await Utilities.getShortUrl(
            `${backupServer()}/api/backups/${backup.id}?session-key=${key}&session-value=${value}&data-email=${encodeURIComponent(
                ApplicationState.dataEmail
            )}`
        );
        window.open(oneTimeLink, '_blank');
    };
    const localisationParams = {
        user: ApplicationState.dataEmail,
    };
    const actionDetailsDescription = ApplicationState.localise('backup.reason.manual.description', localisationParams);
    const createBackupUi = async () => {
        const createBackupResult = await createBackup({ initiatorTask: 'manual', auditInfo: { actionDetailsDescription, items: [] } });
        if (createBackupResult) refresh();
        setBackedUpThisSession(true);
        return createBackupResult;
    };
    const { devMode: isDev } = useStateFlags();
    if (backupTree === null)
        return (
            <DialogRoot sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress />
            </DialogRoot>
        );

    return (
        <DialogRoot>
            <DialogPaper sx={{ p: 2 }}>
                <Typography variant="h6">{ApplicationState.localise('settings.backup-most-recent-backup')}</Typography>
                <Divider sx={{ mb: 2 }} />
                {mostRecentBackup ? (
                    <BackupListItemComponent
                        sx={{ px: 1 }}
                        disablePadding
                        backupDataItem={mostRecentBackup}
                        showDivider={false}
                        isDev={isDev}
                        downloadBackup={downloadBackup}
                        loadBackupIntoMemory={loadBackupIntoMemory}
                        restoreBackupUi={restoreBackupUi}
                    />
                ) : (
                    <Typography variant="body1">{ApplicationState.localise('settings.backup-there-are-no-backups')}</Typography>
                )}
            </DialogPaper>
            {Object.keys(backupTree).length > 0 && (
                <DialogPaper sx={{ p: 2 }}>
                    <Typography variant="h6">Account Backup History</Typography>
                    <Divider sx={{ mb: 2 }} />
                    <SimpleTreeView>
                        {backupTree.map(yearInfo => (
                            <TreeItem itemId={yearInfo.year} label={yearInfo.year} key={yearInfo.year}>
                                {yearInfo.months.map(monthInfo => (
                                    <TreeItem
                                        itemId={`${yearInfo.year}-${monthInfo.month}`}
                                        label={dayjs(`${yearInfo.year}-${monthInfo.month}-01`).format('MMMM')}
                                        key={monthInfo.month}
                                    >
                                        {monthInfo.weeks.map(weekInfo => (
                                            <TreeItem
                                                itemId={`${yearInfo.year}-${monthInfo.month}-${weekInfo.weekCommencingDateAsISOString}`}
                                                label={t('general.week-beginning', {
                                                    date: dayjs(weekInfo.weekCommencingDateAsDate).format('MMMM Do'),
                                                })}
                                                key={weekInfo.weekCommencingDateAsISOString}
                                            >
                                                {weekInfo.backups.map((backupDataItem, index) => {
                                                    return (
                                                        <BackupListItemComponent
                                                            disablePadding={false}
                                                            key={backupDataItem.id}
                                                            showDivider={index < weekInfo.backups.length - 1}
                                                            isDev={isDev}
                                                            backupDataItem={backupDataItem}
                                                            downloadBackup={downloadBackup}
                                                            loadBackupIntoMemory={loadBackupIntoMemory}
                                                            restoreBackupUi={restoreBackupUi}
                                                        />
                                                    );
                                                })}
                                            </TreeItem>
                                        ))}
                                    </TreeItem>
                                ))}
                            </TreeItem>
                        ))}
                    </SimpleTreeView>
                </DialogPaper>
            )}
            <DialogPaper sx={{ p: 2 }}>
                <Button
                    disabled={backedUpThisSession}
                    variant="contained"
                    color="success"
                    endIcon={<CreateBackupIcon />}
                    onClick={createBackupUi}
                >
                    {ApplicationState.localise('settings.backup-run-manual-backup-now')}
                </Button>
            </DialogPaper>
        </DialogRoot>
    );
};

export default ManageBackupsDialogComponent;
